import styled from "@emotion/styled";
import { Form, Field } from "formik";
import { Button } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const InfoForm = styled(Form)``;

export const Input = styled(Field)`
  width: 100%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
`;

export const Select = styled(Input)`
  appearance: none;
  width: 100%;
  height: 100%;
  padding: 12px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;

  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  
`;

export const Option = styled.option`
  width: 70%;
  height: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f7fa;
  border: 1px solid #000000;
`;

export const InputsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  @media screen and (max-width: 768px) {
    &:last-child {
      margin-right: 0px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-right: 30px;
    flex-basis: calc(100% / 2 - 30px);
  }

  @media screen and (min-width: 1200px) {
    flex-basis: calc(100% / 2 - 30px);
  }
`;

export const CancelButton = styled(Button)`
  width: 50%;
  background-color: #e74c3c;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: #c0392b;
    color: #ffffff;
  }
`;

export const CreateButton = styled(CancelButton)`
  width: 50%;
  background-color: #27ae60;

  &:hover,
  &:focus,
  &.active {
    background-color: green;
    color: #ffffff;
  }
`;

