import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  IconButton,
  TrashIconButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  NewsSmallTh,
  NewsLargeTh,
  NewsSmallTd,
  NewsLargeTd,
  NewsText,
  IconLinkContainer,
} from "./PrimaryNewsList.styled";
import { FaTrash, FaEdit } from "react-icons/fa";
import { fetchNews, deleteNews } from "../../../api/news";
import { formattedDateToDayMonthYearWithTime } from "../../../helpers/dateAndTime/formattedDateToDayMonthYearWithTime";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Search } from "../../SearchComponent/SearchComponent";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import css from "./PrimaryNewsList.module.css";
import { ConfirmDeleteModal } from "../../ConfirmDeleteModal/ConfirmDeleteModal";

export const PrimaryNewsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const sortBy = "createdAt";
  const order = "DESC";

  const [inputValue, setInputValue] = useState("");

  const { data: news, refetch } = useQuery(
    [
      "news",
      {
        currentPage,
        pageLimit,
        searchQuery,
        sortBy,
        order,
      },
    ],
    () =>
      fetchNews({
        limit: pageLimit,
        page: currentPage,
        search: searchQuery,
        sortBy,
        order,
      })
  );

  const handleDelete = async (id) => {
    const response = await deleteNews(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      refetch();
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (news) {
      setTotalPages(news?.totalPages);
    }
  }, [news, pageLimit]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (event) => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Main>
      {isModalOpen !== false && (
        <ConfirmDeleteModal
          closeModal={() => setIsModalOpen(false)}
          onConfirm={() => handleDelete(isModalOpen)}
        />
      )}
      <div style={{ margin: "20px", display: "flex", alignItems: "center" }}>
        <Search
          handleSearch={handleSearch}
          handleInputChange={handleInputChange}
          handleKeyPress={handleKeyPress}
          inputValue={inputValue}
        />

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
          style={{ marginLeft: "20px" }}
        />
      </div>

      <Table>
        <Thead>
          <HeadTr>
            <NewsSmallTh style={{ width: "26px" }}>№</NewsSmallTh>
            <NewsSmallTh>Дата</NewsSmallTh>
            <NewsSmallTh>Заголовок</NewsSmallTh>
            <NewsLargeTh>Описання</NewsLargeTh>
            <NewsSmallTh>Посади</NewsSmallTh>
            <NewsSmallTh>Панeль управліня</NewsSmallTh>
          </HeadTr>
        </Thead>
        <tbody>
          {news?.data?.length > 0 ? (
            <>
              {news?.data?.map((item, index) => (
                <Tr key={item?.id}>
                  <NewsSmallTd style={{ width: "48px" }}>
                    {index + 1}
                  </NewsSmallTd>
                  <NewsSmallTd>
                    {formattedDateToDayMonthYearWithTime(item.createdAt)}
                  </NewsSmallTd>
                  <NewsSmallTd>{item.title}</NewsSmallTd>
                  <NewsLargeTd>
                    {" "}
                    <div
                      className={css.newsContent}
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  </NewsLargeTd>
                  <NewsSmallTd>
                    {(() => {
                      if (typeof item?.job_titles === "string") {
                        try {
                          const parsedJobTitles = JSON.parse(item.job_titles);

                          if (Array.isArray(parsedJobTitles)) {
                            return parsedJobTitles.map((jobTitle) => (
                              <NewsText key={jobTitle.id}>
                                {jobTitle.title}
                              </NewsText>
                            ));
                          }
                        } catch (error) {
                          console.error("Помилка при розборі JSON:", error);
                          return <NewsText>Невірний формат даних</NewsText>;
                        }
                      }

                      if (Array.isArray(item?.job_titles)) {
                        return item.job_titles.map((jobTitle) => (
                          <NewsText key={jobTitle.id}>
                            {jobTitle.title}
                          </NewsText>
                        ));
                      }

                      return <NewsText>Немає посад</NewsText>;
                    })()}
                  </NewsSmallTd>

                  <NewsSmallTd>
                    <IconLinkContainer>
                      <IconButton to={`/news/change/${item.id}`}>
                        <FaEdit size="20px" />
                      </IconButton>
                      <TrashIconButton onClick={() => setIsModalOpen(item.id)}>
                        <FaTrash size="20px" />
                      </TrashIconButton>
                    </IconLinkContainer>
                  </NewsSmallTd>
                </Tr>
              ))}
            </>
          ) : (
            <>
              <Tr>
                <Td style={{ width: "100%" }}>Нажаль новин поки що немає</Td>
              </Tr>
            </>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
