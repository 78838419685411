import React, { useState } from "react";
import { useQuery } from "react-query";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import {
  fetchDailyStatistic,
  fetchSummaryByEmployee,
} from "../../../api/statistic";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { Button } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { v4 as uuidv4 } from "uuid";

export const MyStatistics = () => {
  const [sortBy, setSortBy] = useState("order_date");
  const [order, setOrder] = useState("DESC");
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(currentYear, currentMonth, 1)
          : new Date(currentYear, currentMonth, 16),
      endDate:
        new Date().getDate() < 16
          ? new Date(currentYear, currentMonth, 15)
          : new Date(currentYear, currentMonth, lastDayOfMonth),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const {
    data: dailyData,
    isSuccess,
    refetch,
  } = useQuery(["getEmployeeStatistic", { sortBy, order, period }], () =>
    fetchDailyStatistic({
      sortBy,
      order,
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    })
  );

  const { data: summaryData } = useQuery(
    ["fetchMySummaryStatistics", { sortBy, order, period }],
    () =>
      fetchSummaryByEmployee({
        sortBy,
        order,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );

  const handleSortChange = (field) => {
    if (sortBy === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
      return refetch();
    }

    setSortBy(field);
    return refetch();
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  const customDefaultStaticRanges = defaultStaticRanges?.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges?.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "200px" }}
        >
          Оберіть період
        </Button>
      </div>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table>
        <Thead>
          <HeadTr>
            <Th
              onClick={() => handleSortChange("average_total_amount")}
              style={{ width: "70px" }}
            >
              Середній чек {renderSortIcon("average_total_amount")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_order_count")}
              style={{ width: "70px" }}
            >
              Всього замовлень {renderSortIcon("total_order_count")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_products")}
              style={{ width: "70px" }}
            >
              Кількість проданих товарів{renderSortIcon("total_products")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_worked_hours")}
              style={{ width: "70px" }}
            >
              Кількість робочих годин{renderSortIcon("total_worked_hours")}
            </Th>
            <Th
              onClick={() => handleSortChange("average_time_per_order")}
              style={{ width: "70px" }}
            >
              Середній час на 1 апрув{renderSortIcon("average_time_per_order")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")}>
              Викуплених замовлень від всіх оформлених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")}>
              Всього викуплених/невикуплених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("buyout_percentage")}>
              Відсоток викуплених від викуплених/невикуплених{" "}
              {renderSortIcon("buyout_percentage")}
            </Th>
            <Th>По статусах</Th>
            <Th
              onClick={() => handleSortChange("total_hourly_salary")}
              style={{ width: "80px" }}
            >
              Виплата за погодину оплату{renderSortIcon("total_hourly_salary")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_hourly_salary")}
              style={{ width: "80px" }}
            >
              Виплата за замовленя{renderSortIcon("total_hourly_salary")}
            </Th>
            <Th
              onClick={() => handleSortChange("referral_program_salary")}
              style={{ width: "80px" }}
            >
              Приведи друга{renderSortIcon("referral_program_salary")}
            </Th>
            <Th
              onClick={() => handleSortChange("additional_bonus_salary")}
              style={{ width: "80px" }}
            >
              Премія{renderSortIcon("additional_bonus_salary")}
            </Th>
          </HeadTr>
        </Thead>
        {isSuccess && !summaryData ? (
          <tbody>
            <Tr>
              <Td style={{ width: "800px" }}>
                За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
              </Td>
            </Tr>
          </tbody>
        ) : (
          <tbody>
            {summaryData && summaryData?.length > 0 && (
              <Tr key={summaryData[0]?.user_id}>
                <Td style={{ width: "92px" }}>
                  {summaryData[0]?.average_total_amount} грн
                </Td>
                <Td style={{ width: "92px" }}>
                  {summaryData[0]?.total_order_count}
                </Td>
                <Td style={{ width: "92px" }}>
                  {summaryData[0]?.total_products}
                </Td>
                <Td style={{ width: "92px" }}>
                  {summaryData[0]?.total_worked_hours}
                </Td>
                <Td style={{ width: "92px" }}>
                  {summaryData[0]?.average_time_per_order}
                </Td>
                <Td>{summaryData[0]?.total_buyout_orders}</Td>
                <Td>{summaryData[0]?.total_relevant_orders}</Td>
                <Td>{summaryData[0]?.buyout_specific_percentage}%</Td>
                <Td>
                  {summaryData[0]?.statuses?.map((status) => {
                    const orders_percentage =
                      Number(
                        (
                          (status.order_count /
                            (summaryData[0]?.total_order_count +
                              summaryData[0]?.total_recall_orders)) *
                          100
                        )?.toFixed(2)
                      ) || 0;
                    const key = uuidv4();

                    return (
                      <p key={key}>
                        <span style={{ fontWeight: "bold" }}>
                          {status.status_title}
                        </span>{" "}
                        <br />
                        Кількість -{" "}
                        {status.order_count ? status.order_count : 0} <br />
                        Відсоток - {orders_percentage}%
                      </p>
                    );
                  })}
                </Td>
                <Td style={{ width: "102px" }}>
                  {summaryData[0]?.total_hourly_salary} грн
                </Td>
                <Td style={{ width: "102px" }}>
                  {summaryData[0]?.percent_sum} грн
                </Td>
                <Td style={{ width: "102px" }}>
                  {summaryData[0]?.referral_program_salary} грн
                </Td>
                <Td style={{ width: "102px" }}>
                  {summaryData[0]?.additional_bonus_salary} грн
                </Td>
              </Tr>
            )}
          </tbody>
        )}
      </Table>

      <Table style={{ marginTop: "40px" }}>
        <Thead>
          <HeadTr>
            <Th
              onClick={() => handleSortChange("order_date")}
              style={{ width: "70px" }}
            >
              Дата {renderSortIcon("order_date")}
            </Th>
            <Th
              onClick={() => handleSortChange("average_total_amount")}
              style={{ width: "70px" }}
            >
              Середній чек {renderSortIcon("average_total_amount")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_order_count")}
              style={{ width: "70px" }}
            >
              Всього замовлень {renderSortIcon("total_order_count")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_products")}
              style={{ width: "70px" }}
            >
              Кількість проданих товарів{renderSortIcon("total_products")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_worked_hours")}
              style={{ width: "70px" }}
            >
              Кількість робочих годин{renderSortIcon("total_worked_hours")}
            </Th>
            <Th
              onClick={() => handleSortChange("average_time_per_order")}
              style={{ width: "70px" }}
            >
              Середній час на 1 апрув{renderSortIcon("average_time_per_order")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")}>
              Викуплених замовлень від всіх оформлених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("total_buyout_orders")}>
              Всього викуплених/невикуплених{" "}
              {renderSortIcon("total_buyout_orders")}
            </Th>
            <Th onClick={() => handleSortChange("buyout_percentage")}>
              Відсоток викуплених від викуплених/невикуплених{" "}
              {renderSortIcon("buyout_percentage")}
            </Th>
            <Th>По статусах</Th>
            <Th
              onClick={() => handleSortChange("total_hourly_salary")}
              style={{ width: "80px" }}
            >
              Виплата за погодину оплату{renderSortIcon("total_hourly_salary")}
            </Th>
            <Th
              onClick={() => handleSortChange("total_hourly_salary")}
              style={{ width: "80px" }}
            >
              Виплата за замовленя{renderSortIcon("total_hourly_salary")}
            </Th>
            <Th
              onClick={() => handleSortChange("referral_program_salary")}
              style={{ width: "80px" }}
            >
              Приведи друга{renderSortIcon("referral_program_salary")}
            </Th>
            <Th
              onClick={() => handleSortChange("additional_bonus_salary")}
              style={{ width: "80px" }}
            >
              Премія{renderSortIcon("additional_bonus_salary")}
            </Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!dailyData || dailyData?.length === 0) ? (
          <tbody>
            <Tr>
              <Td style={{ width: "800px" }}>
                За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
              </Td>
              <Th
                onClick={() => handleSortChange("referral_program_salary")}
                style={{ width: "80px" }}
              >
                Приведи друга{renderSortIcon("referral_program_salary")}
              </Th>
              <Th
                onClick={() => handleSortChange("additional_bonus_salary")}
                style={{ width: "80px" }}
              >
                Премія{renderSortIcon("additional_bonus_salary")}
              </Th>
            </Tr>
          </tbody>
        ) : (
          <tbody>
            {dailyData &&
              dailyData?.map((item) => {
                const key = uuidv4();
                return (
                  <Tr key={key}>
                    <Td style={{ width: "92px" }}>
                      {formattedDateToDayMonthYear(item.order_date)}
                    </Td>
                    <Td style={{ width: "92px" }}>
                      {Number(item.average_total_amount)
                        ? item.average_total_amount
                        : 0}{" "}
                      грн
                    </Td>
                    <Td style={{ width: "92px" }}>{item.total_order_count}</Td>
                    <Td style={{ width: "92px" }}>{item.total_products}</Td>
                    <Td style={{ width: "92px" }}>{item.total_worked_hours}</Td>
                    <Td style={{ width: "92px" }}>
                      {item.average_time_per_order}
                    </Td>
                    <Td>{item.total_buyout_orders}</Td>
                    <Td>{item.total_relevant_orders}</Td>
                    <Td>{item.buyout_specific_percentage?.toFixed(2)}%</Td>
                    <Td>
                      {item?.statuses?.map((status) => {
                        const orders_percentage =
                          Number(
                            (
                              (status.order_count /
                                (item.total_order_count +
                                  item.total_recall_orders)) *
                              100
                            )?.toFixed(2)
                          ) || 0;

                        return (
                          <p key={status.order_status_id}>
                            <span style={{ fontWeight: "bold" }}>
                              {status.status_title}
                            </span>{" "}
                            <br />
                            Кількість -{" "}
                            {status.order_count ? status.order_count : 0} <br />
                            Відсоток - {orders_percentage}%
                          </p>
                        );
                      })}
                    </Td>
                    <Td style={{ width: "102px" }}>
                      {item.total_hourly_salary?.toFixed(0)} грн
                    </Td>
                    <Td style={{ width: "102px" }}>{item.percent_sum} грн</Td>
                    <Td style={{ width: "102px" }}>
                      {item?.referral_program_salary || 0} грн
                    </Td>
                    <Td style={{ width: "102px" }}>
                      {item.additional_bonus_salary} грн
                    </Td>
                  </Tr>
                );
              })}
          </tbody>
        )}
      </Table>
    </Main>
  );
};
