import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFiredEmployeeStatuses,
  updateFiredEmployeeStatuses,
  createFiredEmployeeStatuses,
  deleteFiredEmployeeStatusesService,
} from "../../../api/firedEmployeeStatuses";

import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Input,
  TrashIconButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  FiredEmployeeStatusesTh,
  FiredEmployeeStatusesTd,
  IconLinkContainer,
} from "./FiredEmployeeStatuses.styled";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { ConfirmDeleteModal } from "../../ConfirmDeleteModal/ConfirmDeleteModal";

export const FiredEmployeeStatuses = () => {
  const [addingNew, setAddingNew] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    title: "",
  });

  const handleCancelEdit = () => {
    resetValue();
    setAddingNew(false);
  };

  const handleEdit = async (item) => {
    setFormData({ ...item });
    setIsUpdate(true);
    setAddingNew(true);
  };

  const { data, refetch } = useQuery(
    "firedEmployeeStatuses",
    getFiredEmployeeStatuses
  );

  const handleDelete = async (positionId) => {
    const response = await deleteFiredEmployeeStatusesService(positionId);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      handleCancelEdit();
      refetch();
    }
  };

  const resetValue = () => {
    setFormData({
      id: "",
      title: "",
    });
  };

  const handleUpdate = async () => {
    const response = await updateFiredEmployeeStatuses(formData);

    if (response?.status === 200) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNew(false);
      refetch();
    }
  };

  const handleSubmit = async () => {
    if (!formData?.title) {
      toast.error("Будь ласка, введіть назву статусу.");
      return;
    }

    const response = await createFiredEmployeeStatuses(formData);

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNew(false);
      refetch();
    }
  };

  return (
    <Main>
      {isModalOpen !== false && (
        <ConfirmDeleteModal
          closeModal={() => setIsModalOpen(false)}
          onConfirm={() => handleDelete(isModalOpen)}
        />
      )}
      <Table>
        <Thead>
          <HeadTr>
            <FiredEmployeeStatusesTh>Назва статусу</FiredEmployeeStatusesTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.data?.length > 0 ? (
            <React.Fragment>
              {data?.data?.map((item) => (
                <Tr key={item?.id}>
                  {addingNew && isUpdated && formData?.id === item?.id ? (
                    <>
                      <FiredEmployeeStatusesTd>
                        <Input
                          type="text"
                          value={formData.title}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              title: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </FiredEmployeeStatusesTd>
                      <Td>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="20px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="25px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <FiredEmployeeStatusesTd>
                        {item?.title}
                      </FiredEmployeeStatusesTd>
                      <Td>
                        {formData?.id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton onClick={() => handleEdit(item)}>
                              <FaEdit size="20px" />
                            </IconButton>
                            <TrashIconButton
                              onClick={() => setIsModalOpen(item.id)}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
              <Tr>
                {addingNew && !isUpdated ? (
                  <>
                    <FiredEmployeeStatusesTd>
                      <Input
                        type="text"
                        value={formData.title}
                        onChange={(e) => {
                          const updatedFormData = {
                            ...formData,
                            title: e.target.value,
                          };
                          return setFormData(updatedFormData);
                        }}
                      />
                    </FiredEmployeeStatusesTd>
                    <Td>
                      <IconLinkContainer>
                        <IconButton onClick={handleSubmit}>
                          <FaSave size="20px" />
                        </IconButton>
                        <TrashIconButton onClick={() => handleCancelEdit()}>
                          <MdCancel size="25px" />
                        </TrashIconButton>
                      </IconLinkContainer>
                    </Td>
                  </>
                ) : (
                  <>
                    <FiredEmployeeStatusesTd></FiredEmployeeStatusesTd>
                    <Td>
                      <IconButton
                        onClick={() => setAddingNew(true)}
                        style={{ width: "75%" }}
                      >
                        <MdOutlineAddCircle size="25px" />
                      </IconButton>
                    </Td>
                  </>
                )}
              </Tr>
            </React.Fragment>
          ) : (
            <Tr>
              {addingNew ? (
                <>
                  <FiredEmployeeStatusesTd>
                    <Input
                      type="text"
                      value={formData.title}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          title: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </FiredEmployeeStatusesTd>

                  <Td>
                    <IconLinkContainer>
                      <IconButton onClick={handleSubmit}>
                        <FaSave size="20px" />
                      </IconButton>
                      <TrashIconButton onClick={() => handleCancelEdit()}>
                        <MdCancel size="25px" />
                      </TrashIconButton>
                    </IconLinkContainer>
                  </Td>
                </>
              ) : (
                <>
                  <FiredEmployeeStatusesTd></FiredEmployeeStatusesTd>
                  <Td>
                    <IconButton
                      onClick={() => setAddingNew(true)}
                      style={{ width: "75%" }}
                    >
                      <MdOutlineAddCircle size="25px" />
                    </IconButton>
                  </Td>
                </>
              )}
            </Tr>
          )}
        </tbody>
      </Table>
    </Main>
  );
};
