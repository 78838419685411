import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchReturnOrderStatuses = async ({
    sortBy,
    order,
    searchQuery,
    currentPage,
    pageLimit,
    id
  }) => {
    try {
      const response = await makeRequestSQL.get('/return-order-statuses/', {
        params: {
          sortBy,
          order,
          search: searchQuery,
          page: currentPage,
          limit: pageLimit,
          id
        },
      });
      const decryptedData = await  decryptMiddleware(response.data);
  
      return decryptedData?.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const updateReturnOrderStatuses = async data => {
    try {
      const response = await makeRequestSQL.put('/return-order-statuses/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const createReturnOrderStatuses = async data => {
    try {
      const response = await makeRequestSQL.post('/return-order-statuses/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const deleteReturnOrderStatuses = async id => {
    try {
      const response = await makeRequestSQL.delete('/return-order-statuses/', {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };