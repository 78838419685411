import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";


export const fetchOrderMePushToPickup = async ({
  page,
  limit,
  sortBy,
  order,
  pickupDateStatus,
  nonPurchaseReasonStatus,
  startDate,
  endDate,
  order_number
}) => {
  try {
    const response = await makeRequestSQL.get("/order-push-to-pickup/me", {
      params: {
        page,
        limit,
        sortBy,
        order,
        pickupDateStatus,
        nonPurchaseReasonStatus,
        startDate,
        endDate,
        order_number
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOrderPushToPickup = async ({
  page,
  limit,
  search,
  sortBy,
  order,
  employee,
  status,
  startDate,
  endDate,
  order_number
}) => {
  try {
    const response = await makeRequestSQL.get("/order-push-to-pickup/", {
      params: {
        page,
        limit,
        search,
        sortBy,
        order,
        employee,
        status,
        startDate,
        endDate,
        order_number
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createOrderPushToPickup = async (data) => {
  try {
    const response = await makeRequestSQL.post("/order-push-to-pickup", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateOrderPushToPickup = async (data) => {
  try {
    const response = await makeRequestSQL.put("/order-push-to-pickup", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteOrderPushToPickup = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/order-push-to-pickup`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
