import { Main } from "../../components/GlobalComponentsAndStyles/Main";
import { LinkButton } from "../../components/GlobalComponentsAndStyles/GlobalStyles.styled";
import { SharedLayout } from "../../components/SharedLayout/SharedLayout";
import {
  NotFoundContainer,
  NotFoundImage,
  NotFoundText,
} from "./NotFound.styled";
import notFoundImage from "../../images/404.png";

export const NotFound = () => {
  return (
    <div>
      <SharedLayout />
      <Main>
        <NotFoundContainer>
          <NotFoundImage src={notFoundImage} alt="Сторінку не знайдено" />
          <NotFoundText>
            Обраний маршрут не знайдено, перевірте правильність маршруту або
            натисніть на головну
          </NotFoundText>
          <NotFoundContainer>
            <LinkButton to={`/`}>На головну</LinkButton>
          </NotFoundContainer>
        </NotFoundContainer>
      </Main>
    </div>
  );
};
