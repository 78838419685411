import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Main } from "../GlobalComponentsAndStyles/Main";
import { FileUpload } from "../FileUpload/FileUpload";
import { Modal } from "../Modal/Modal";
import { toast } from "react-toastify";
import {
  uploadContacts,
  fetchContacts,
  updateContact,
  createOneContact,
  deleteContact,
} from "../../api/contacts";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Input,
  Option,
  TrashIconButton,
  Button,
  FilterSelect,
  FilterOption,
} from "../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
  ContactSelect,
} from "./Contacts.styled";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";
import { formattedDateToDayMonthYearWithTime } from "../../helpers/dateAndTime/formattedDateToDayMonthYearWithTime";
import { formattedDateToDayMonthYear } from "../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { getContactStatuses } from "../../api/settings";
import { formatDateToInput } from "../../helpers/dateAndTime/formatDate";
import { Search } from "../SearchComponent/SearchComponent";
import { PageLimitSelector } from "../PageLimitSelector/PageLimitSelector";
import { Pagination } from "../Pagination/Pagination";
import { ConfirmDeleteModal } from "../ConfirmDeleteModal/ConfirmDeleteModal";

export const PrimaryContacts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("updateAt");
  const [order, setOrder] = useState("DESC");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    date_of_application: "",
    action_and_comment_from_7leads_supervisor: "",
    full_name: "",
    contact: "",
    birthday: "",
    city: "",
    work_hours: "",
    wish_salary: "",
    have_experience: "",
    company_name: "",
    know_from: "",
    pc_scills: "",
    social_links: "",
    open_windows_task: "",
    combination_copy_text: "",
    characterize: "",
    knowledge_ukrainian_langugage: "",
    status_id: "1f842610-e895-4b9c-8266-7217ad2bf30b",
    status_title: "",
    dcm_comment: "",
    employee_full_name: "",
  });

  const { data: statuses } = useQuery("contactsStatuses", getContactStatuses);

  const { data, error, isLoading, isSuccess, refetch } = useQuery(
    [
      "getContacts",
      {
        searchQuery,
        sortBy,
        order,
        selectedStatus,
        currentPage,
        pageLimit,
        selectedEmployee,
      },
    ],
    () =>
      fetchContacts({
        search: searchQuery,
        sortBy,
        order,
        status_id: selectedStatus,
        page: currentPage,
        limit: pageLimit,
        employee_id: selectedEmployee,
      })
  );

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
  };

  const onUpload = async () => {
    const formData = new FormData();
    formData.append("contacts", file);

    try {
      setIsUploadLoading(true);
      const response = await uploadContacts(formData);

      if (response.status === 204) {
        setIsModalOpen(false);
        toast.success(response.data.message || response.message);
        setIsUploadLoading(false);
        refetch();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      setIsUploadLoading(false);
    }
  };

  const handleSubmit = async () => {
    const response = await createOneContact(formData);

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNew(false);
      refetch();
    }
  };

  const resetValue = () => {
    setFormData({
      id: "",
      date_of_application: "",
      action_and_comment_from_7leads_supervisor: "",
      full_name: "",
      contact: "",
      birthday: "",
      city: "",
      work_hours: "",
      wish_salary: "",
      have_experience: "",
      company_name: "",
      know_from: "",
      pc_scills: "",
      social_links: "",
      open_windows_task: "",
      combination_copy_text: "",
      characterize: "",
      knowledge_ukrainian_langugage: "",
      status_id: "",
      status_title: "",
      employee_full_name: "",
    });
  };

  const handleUpdate = async () => {
    const response = await updateContact(formData);

    if (response?.status === 200) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNew(false);
      refetch();
    }
  };

  const handleCancelEdit = () => {
    resetValue();
    setAddingNew(false);
    setIsUpdate(false);
  };

  const handleEdit = async (item) => {
    const updatedFormData = {
      ...item,
    };

    setFormData(updatedFormData);
    setIsUpdate(true);
    setAddingNew(true);
  };

  const handleDelete = async (id) => {
    const response = await deleteContact(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      handleCancelEdit();
      refetch();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (event) => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <Main>
      {isModalOpenConfirm !== false && (
        <ConfirmDeleteModal
          closeModal={() => setIsModalOpenConfirm(false)}
          onConfirm={() => handleDelete(isModalOpenConfirm)}
        />
      )}
      {isModalOpen ? (
        <Modal closeModal={() => setIsModalOpen(false)}>
          <FileUpload
            onFileChange={onFileChange}
            onUpload={onUpload}
            fileName={fileName}
            isUploadLoading={isUploadLoading}
            setFile={setFile}
            setFileName={setFileName}
          />
        </Modal>
      ) : (
        <div style={{ margin: "20px" }}>
          <Button
            onClick={() => setIsModalOpen(true)}
            style={{ width: "231px", height: "36px", marginBottom: "20px" }}
          >
            Завантажити новий файл
          </Button>
          <Search
            handleSearch={handleSearch}
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            inputValue={inputValue}
          />
          <div style={{ margin: "20px 0", display: "flex" }}>
            {/* <label style={{ marginLeft: "10px" }}>Оберіть працівника: </label>
                <FilterSelect
                  value={selectedEmployee}
                  onChange={(e) => {
                    setSelectedEmployee(e.target.value);
                    setCurrentPage(1);
                  }}
                  style={{ marginRight: "5px", width: "400px" }}
                >
                  <FilterOption value="">Виберіть працівника</FilterOption>
                  {data?.data?.map((employee) => (
                    <FilterOption key={employee?.user_id} value={employee?.user_id}>
                      {employee?.full_name}
                    </FilterOption>
                  ))}
                </FilterSelect> */}

            <label style={{ marginLeft: "10px" }}>Оберіть статус: </label>
            <FilterSelect
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                setCurrentPage(1);
              }}
              style={{ marginRight: "5px", width: "400px" }}
            >
              <FilterOption value="">Виберіть статус</FilterOption>
              {statuses?.map((status) => (
                <FilterOption key={status?.id} value={status?.id}>
                  {status?.title}
                </FilterOption>
              ))}
            </FilterSelect>

            <PageLimitSelector
              pageLimit={pageLimit}
              onChange={handlePageLimitChange}
            />
          </div>
        </div>
      )}

      <div>
        <Table>
          <Thead>
            <HeadTr>
              <Th>Панeль управліня</Th>
              <RouteTh>Дата коли заповнили анкету</RouteTh>
              <RouteTh>Статус</RouteTh>
              <RouteTh>ПІБ кандидата</RouteTh>
              <RouteTh>
                Логін у telegram (або номер до якого він прив'язаний)
              </RouteTh>
              <RouteTh>Підсумок партнерів</RouteTh>
              <RouteTh>Дата народження</RouteTh>
              <RouteTh>Місто проживаня</RouteTh>
              <RouteTh>Скільки годин на тиждень готові працювати?</RouteTh>
              <RouteTh>Орієнтовний рівень заробітної плати</RouteTh>
              <RouteTh>Досвід роботи в КЦ</RouteTh>
              <RouteTh>Назви КЦ в яких працював кандидат</RouteTh>
              <RouteTh>Дізнався про вакансію з</RouteTh>
              <RouteTh>Навичка володіння ПК</RouteTh>
              <RouteTh>Посилання на профіль Facebook або Instagram</RouteTh>
              <RouteTh>Як відкрити диспетчер завдань Windows?</RouteTh>
              <RouteTh>Якою комбінацією клавіш можна копіювати текст?</RouteTh>
              <RouteTh>
                Назвіть декілька якостей, які Вас характеризують
              </RouteTh>
              <RouteTh>
                Знання української мови (розуміти, що каже клієнт)
              </RouteTh>
              <RouteTh>Комментар</RouteTh>
              <RouteTh>Ким внесено зміни</RouteTh>
              <RouteTh>Дата останьої зміни</RouteTh>
            </HeadTr>
          </Thead>
          <tbody>
            <Tr>
              {addingNew && !isUpdated ? (
                <>
                  <Td>
                    <IconLinkContainer>
                      <IconButton onClick={handleSubmit}>
                        <FaSave size="20px" />
                      </IconButton>
                      <TrashIconButton onClick={() => handleCancelEdit()}>
                        <MdCancel size="25px" />
                      </TrashIconButton>
                    </IconLinkContainer>
                  </Td>
                  <RouteTd>
                    {formattedDateToDayMonthYearWithTime(
                      formData.date_of_application
                    )}
                  </RouteTd>
                  <RouteTd>
                    <ContactSelect
                      value={formData?.status_id}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          status_id: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                      disabled
                    >
                      <Option value="">Виберіть статус</Option>
                      {statuses?.map((status) => (
                        <Option key={status?.id} value={status?.id}>
                          {status?.title}
                        </Option>
                      ))}
                    </ContactSelect>
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.full_name || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          full_name: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.contact || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          contact: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={
                        formData.action_and_comment_from_7leads_supervisor || ""
                      }
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          action_and_comment_from_7leads_supervisor:
                            e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="date"
                      value={formatDateToInput(formData.birthday) || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          birthday: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.city || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          city: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.work_hours || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          work_hours: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.wish_salary || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          wish_salary: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.have_experience || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          have_experience: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.company_name || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          company_name: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.know_from || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          know_from: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.pc_scills || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          pc_scills: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.social_links || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          social_links: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.open_windows_task || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          open_windows_task: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.combination_copy_text || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          combination_copy_text: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.characterize || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          characterize: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.knowledge_ukrainian_langugage || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          knowledge_ukrainian_langugage: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.dcm_comment || ""}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          dcm_comment: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>{formData.employee_full_name || ""}</RouteTd>
                  <RouteTd>
                    {formattedDateToDayMonthYearWithTime(formData.updateAt)}
                  </RouteTd>
                </>
              ) : (
                <>
                  <Td>
                    <IconButton
                      onClick={() => setAddingNew(true)}
                      style={{ width: "75%" }}
                    >
                      <MdOutlineAddCircle size="25px" />
                    </IconButton>
                  </Td>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                </>
              )}
            </Tr>
            {data?.data?.length > 0 && (
              <React.Fragment>
                {data?.data?.map((item) => (
                  <Tr key={item?.id}>
                    {addingNew && isUpdated && formData?.id === item?.id ? (
                      <>
                        <Td>
                          <IconLinkContainer>
                            <IconButton onClick={handleUpdate}>
                              <FaSave size="20px" />
                            </IconButton>
                            <TrashIconButton onClick={() => handleCancelEdit()}>
                              <MdCancel size="25px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        </Td>
                        <RouteTd>
                          {formattedDateToDayMonthYearWithTime(
                            item.date_of_application
                          )}
                        </RouteTd>
                        <RouteTd>
                          <ContactSelect
                            value={formData?.status_id}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                status_id: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          >
                            <Option value="">Виберіть статус</Option>
                            {statuses?.map((status) => (
                              <Option key={status?.id} value={status?.id}>
                                {status?.title}
                              </Option>
                            ))}
                          </ContactSelect>
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.full_name || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                full_name: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.contact || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                contact: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={
                              formData.action_and_comment_from_7leads_supervisor ||
                              ""
                            }
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                action_and_comment_from_7leads_supervisor:
                                  e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="date"
                            value={formatDateToInput(formData.birthday) || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                birthday: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.city || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                city: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.work_hours || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                work_hours: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.wish_salary || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                wish_salary: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.have_experience || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                have_experience: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.company_name || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                company_name: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.know_from || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                know_from: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.pc_scills || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                pc_scills: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.social_links || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                social_links: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.open_windows_task || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                open_windows_task: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.combination_copy_text || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                combination_copy_text: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.characterize || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                characterize: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.knowledge_ukrainian_langugage || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                knowledge_ukrainian_langugage: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>
                          <Input
                            type="text"
                            value={formData.dcm_comment || ""}
                            onChange={(e) => {
                              const updatedFormData = {
                                ...formData,
                                dcm_comment: e.target.value,
                              };
                              return setFormData(updatedFormData);
                            }}
                          />
                        </RouteTd>

                        <RouteTd>{formData.employee_full_name || ""}</RouteTd>
                        <RouteTd>
                          {formattedDateToDayMonthYearWithTime(
                            formData.updateAt
                          )}
                        </RouteTd>
                      </>
                    ) : (
                      <>
                        <Td>
                          {formData?.id !== item?.id && (
                            <IconLinkContainer>
                              <IconButton onClick={() => handleEdit(item)}>
                                <FaEdit size="20px" />
                              </IconButton>
                              <TrashIconButton
                                onClick={() => setIsModalOpenConfirm(item.id)}
                              >
                                <FaTrash size="20px" />
                              </TrashIconButton>
                            </IconLinkContainer>
                          )}
                        </Td>
                        <RouteTd>
                          {formattedDateToDayMonthYearWithTime(
                            item.date_of_application
                          )}
                        </RouteTd>
                        <RouteTd>{item?.status_title}</RouteTd>
                        <RouteTd>{item?.full_name}</RouteTd>
                        <RouteTd>{item?.contact}</RouteTd>
                        <RouteTd>
                          {item?.action_and_comment_from_7leads_supervisor}
                        </RouteTd>
                        <RouteTd>
                          {" "}
                          {formattedDateToDayMonthYear(item.birthday)}
                        </RouteTd>
                        <RouteTd>{item?.city}</RouteTd>
                        <RouteTd>{item?.work_hours} </RouteTd>
                        <RouteTd> {item?.wish_salary}</RouteTd>
                        <RouteTd>{item?.have_experience} </RouteTd>
                        <RouteTd>{item?.company_name} </RouteTd>
                        <RouteTd>{item?.know_from} </RouteTd>

                        <RouteTd>{item?.pc_scills}</RouteTd>
                        <RouteTd>{item?.social_links}</RouteTd>
                        <RouteTd>{item?.open_windows_task}</RouteTd>
                        <RouteTd>{item?.combination_copy_text}</RouteTd>
                        <RouteTd>{item?.characterize}</RouteTd>
                        <RouteTd>{item?.knowledge_ukrainian_langugage}</RouteTd>
                        <RouteTd>{item?.dcm_comment}</RouteTd>
                        <RouteTd>{item.employee_full_name || ""}</RouteTd>
                        <RouteTd>
                          {formattedDateToDayMonthYearWithTime(item.updateAt)}
                        </RouteTd>
                      </>
                    )}
                  </Tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </Table>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </Main>
  );
};
