import {
  Option,
  Input,
  InfoForm,
  InputsList,
  InputGroup,
  Select,
  CreateButton,
  CancelButton,
} from "./OrderItemPushToPickup.styled";
import {
  ButtonContainer,
  Label,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchTakeOrderStatuses } from "../../../api/takeOrderStatuses";
import { fetchReturnOrderStatuses } from "../../../api/returnOrderStatuses";
import {
  fetchOrderMePushToPickup,
  updateOrderPushToPickup,
  createOrderPushToPickup,
} from "../../../api/orderPushToPickup";

export const OrderItemPushToPickup = () => {
  const location = useLocation();
  const path = location?.pathname;
  const navigate = useNavigate();
  const [isCreated, setIsCreated] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const [initialValues, setInitialValues] = useState({
    id: "",
    order_number: "",
    incoming_call_from_company: "",
    sms_notification_sent: "",
    pickup_date: "",
    non_purchase_reason: "",
    comment: "",
  });

  const { data: takeOrderStatusData } = useQuery(
    "takeOrderStatus",
    fetchTakeOrderStatuses
  );

  const { data: returnOrderStatusData } = useQuery(
    "returnOrderStatusData",
    fetchReturnOrderStatuses
  );

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");

      if (parts?.length >= 4) {
        const getId = parts[4];
        setSelectedId(getId);
      }

      if (parts?.length > 2) {
        const checkUpdate = `/${parts[3]}` === "/change";
        const checkCreate = `/${parts[3]}` === "/create";

        if (checkUpdate) {
          setIsUpdate(true);
        }

        if (checkCreate) {
          setIsCreated(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    const fetchClaim = async () => {
      if (isCreated) return;

      if (isUpdate) {
        const response = await fetchOrderMePushToPickup({
          order_number: selectedId,
        });

        if (response?.data?.length > 0) {
          const fetchData = response?.data[0];
          const updatedInitialValue = {};

          Object.entries(fetchData).forEach(([key, value]) => {
            updatedInitialValue[key] = value ?? "";
          });

          setInitialValues(updatedInitialValue);
        }
      }
    };

    fetchClaim();
  }, [location, isCreated, selectedId, isUpdate]);

  const handleSubmit = async (values, resetForm) => {
    setLoading(true);

    if (isCreated) {
      const response = await createOrderPushToPickup(values);
      if (response?.status === 201) {
        toast.success(response?.data?.message);
        resetForm();
        setLoading(false);
        return navigate("/order/list-push-to-pickup");
      }
    }

    if (isUpdate) {
      const response = await updateOrderPushToPickup({
        ...values,
      });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        resetForm();
        setLoading(false);
        return navigate("/order/list-push-to-pickup");
      }
    }
  };

  const handleCancel = () => {
    return navigate("/order/list-push-to-pickup");
  };

  return (
    <Main>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            height: "5vh",
          }}
        >
          <ClipLoader color="#3098d6" loading={loading} size={30} />
        </div>
      )}
      <Formik
        key={initialValues.id}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, resetForm);
          setSubmitting(false);
        }}
      >
        {({ isValid }) => (
          <InfoForm autoComplete="off">
            <InputsList>
              <InputGroup>
                <Label>Введіть номер замовлення</Label>
                <Input
                  placeholder="Введіть номер замовлення"
                  type="number"
                  name="order_number"
                />
              </InputGroup>

              <InputGroup>
                <Label>Чи був дзвінок відкомпанії?</Label>
                <Select as="select" name="incoming_call_from_company">
                  <Option key="default-key">Чи був дзвінок відкомпанії?</Option>

                  <Option value="false" key={"false1"}>
                    Ні
                  </Option>
                  <Option value="true" key={"true1"}>
                    Так
                  </Option>
                </Select>
              </InputGroup>

              <InputGroup>
                <Label>Чи було смс з ТТН?</Label>
                <Select as="select" name="sms_notification_sent">
                  <Option key="default-key">Чи було смс з ТТН?</Option>

                  <Option value="false" key={"false2"}>
                    Ні
                  </Option>
                  <Option value="true" key={"true2"}>
                    Так
                  </Option>
                </Select>
              </InputGroup>

              <InputGroup>
                <Label>Коли забере замовлення?</Label>
                <Select as="select" name="pickup_date">
                  <Option key="default-key">Коли забере замовлення?</Option>
                  {takeOrderStatusData?.map((item) => (
                    <Option value={item?.id} key={item?.id}>
                      {item?.title}
                    </Option>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup>
                <Label>Вкажіть причину не викупу</Label>
                <Select as="select" name="non_purchase_reason">
                  <Option key="default-key">Вкажіть причину не викупу:</Option>
                  {returnOrderStatusData?.map((item) => (
                    <Option value={item?.id} key={item?.id}>
                      {item?.title}
                    </Option>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup>
                <Label>Введіть коментар</Label>
                <Input
                  placeholder="Введіть коментар"
                  type="text"
                  name="comment"
                />
              </InputGroup>
            </InputsList>
            <ButtonContainer>
              <CreateButton type="submit">
                {isCreated ? "Створити замовлення" : "Внести зміни"}
              </CreateButton>

              <CancelButton type="button" onClick={handleCancel}>
                Скасувати
              </CancelButton>
            </ButtonContainer>
          </InfoForm>
        )}
      </Formik>
    </Main>
  );
};
