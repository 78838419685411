import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { fetchAllSchedule } from "../../../api/schedule";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { ScheduleTable, ScheduleTr, ScheduleTh, ScheduleTd, ScheduleMeNavContainer } from "./HourlySchedule.styled";
import { Button, StickyTd } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { AiOutlineFieldTime } from "react-icons/ai";

export const HourlySchedule = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [filteredDays, setFilteredDays] = useState([]);
  
  const { data } = useQuery(["schedule", currentYear, currentMonth], () =>
    fetchAllSchedule({ year: currentYear, month: currentMonth })
  );

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    setFilteredDays([...Array(days).keys()].map((day) => day + 1));
  }, [currentMonth, currentYear]);

  const changeMonth = (direction) => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear((prev) => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear((prev) => prev - 1);
    } else {
      setCurrentMonth((prev) => prev + direction);
    }
  };

  const calculateWorkersPerHour = () => {
    const workersPerHour = {};
    
    filteredDays.forEach(day => {
      for (let hour = 8; hour <= 21; hour++) {
        workersPerHour[`${day}-${hour}`] = 0;
      }
    });
    
    if (data) {
      data.forEach(user => {
        user.schedules.forEach(schedule => {
          const scheduleDate = new Date(schedule.day).getDate();
          schedule.working_time.forEach(time => {
            const startHour = parseInt(time.start.split(":")[0]);
            const endHour = parseInt(time.end.split(":")[0]);
            
            for (let hour = startHour; hour < endHour; hour++) {
              if (workersPerHour[`${scheduleDate}-${hour}`] !== undefined) {
                workersPerHour[`${scheduleDate}-${hour}`]++;
              }
            }
          });
        });
      });
    }

    return workersPerHour;
  };

  const getColor = (count) => {
    if (count < 5) return "#f5b7b1";
    if (count <= 15) return "#d4edda";
    if (count <= 25) return "#a3e4d7";
    if (count <= 50) return "#85c1e9";
    return "#f5b7b1";
  };

  const workersPerHour = calculateWorkersPerHour();

  return (
    <Main>
      <ScheduleMeNavContainer style={{ width: "500px" }}>
        <Button onClick={() => changeMonth(-1)}>Минулий місяць</Button>
        <Button onClick={() => changeMonth(1)} style={{ marginLeft: "10px" }}>
          Наступний місяць
        </Button>
      </ScheduleMeNavContainer>
      <ScheduleTable>
        <thead>
          <ScheduleTr>
            <ScheduleTh><AiOutlineFieldTime size="24px" /></ScheduleTh>
            {filteredDays.map(day => (
              <ScheduleTh key={day}>{day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}</ScheduleTh>
            ))}
          </ScheduleTr>
        </thead>
        <tbody>
          {Array.from({ length: 14 }).map((_, idx) => {
            const hour = 8 + idx;
            return (
              <ScheduleTr key={idx}>
                <StickyTd style={{width: '95px'}}>{`${hour}:00 - ${hour + 1}:00`}</StickyTd>
                {filteredDays.map(day => (
                  <ScheduleTd key={day} style={{ fontWeight: "700", fontSize: "18px", backgroundColor: getColor(workersPerHour[`${day}-${hour}`] || 0) }}>
                    {workersPerHour[`${day}-${hour}`] || 0}
                  </ScheduleTd>
                ))}
              </ScheduleTr>
            );
          })}
        </tbody>
      </ScheduleTable>
    </Main>
  );
};
