import { Main } from "../../components/GlobalComponentsAndStyles/Main";
import {
  PrimaryText,
  RedTextColor,
  OrangeBackgroundPrimaryTitle,
  GreenBackgroundPrimaryTitle,
  GreenTextColor,
} from "../../components/GlobalComponentsAndStyles/GlobalStyles.styled";
import { Outlet } from "react-router-dom";
import {
  PrimaryList,
  SecondaryList,
  PimaryItem,
  SecondaryItem,
  ScriptNavLink,
} from "./Script.styled";
import { ScrollToTopButton } from "../../components/ScrollToTopButton/ScrollToTopButton";

export const ScriptPushToPickup = () => {
  return (
    <div style={{ userSelect: "none" }}>
      <Main>
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "70%" }}>
            <section id="greeting">
              <GreenBackgroundPrimaryTitle>Основа</GreenBackgroundPrimaryTitle>
              <PrimaryText>
                Доброго дня <GreenTextColor>(Ім'я клієнта)</GreenTextColor>,
                мене звати <GreenTextColor>(Ваше Ім'я)</GreenTextColor> я
                старший менеджер компанії DCM{" "}
                <GreenTextColor>(Дисконт центр)</GreenTextColor>. Ваше
                замовлення на{" "}
                <GreenTextColor>(Називаємо назву товара)</GreenTextColor>
                вже <GreenTextColor>
                  (Називаємо кількість днів)
                </GreenTextColor>{" "}
                дні знаходиться у відділенні{" "}
                <GreenTextColor>(Називаємо номер відділення)</GreenTextColor>
                <GreenTextColor>
                  (Обираємо статус і вказуємо в коментар)
                </GreenTextColor>
              </PrimaryText>

              <PrimaryText>
                -<GreenTextColor>(Ім'я клієнта)</GreenTextColor> Підкажіть чи
                отримували Ви від нас інформацію про посилку з трек кодом?{" "}
                <RedTextColor>Чекаємо відповідь</RedTextColor>
              </PrimaryText>

              <PrimaryText>
                <GreenTextColor>Якщо так </GreenTextColor>
              </PrimaryText>

              <PrimaryText>
                А поступив дзвінок або смс з трек кодом?{" "}
                <RedTextColor>
                  (обираємо в статусах в бланці замовлення)
                </RedTextColor>
              </PrimaryText>

              <PrimaryText>
                Чи є якась причина, чому Ви ще не змогли отримати посилку?
                Можливо, потрібна допомога?{" "}
                <RedTextColor>
                  (якщо є відповідаємо на запитання якщо немає то переходимо до
                  Завершення розмови)
                </RedTextColor>
              </PrimaryText>

              <div id="objections-1">
                <OrangeBackgroundPrimaryTitle>
                  Якщо не планує забирати
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Підкажіть будь ласка якщо не секрет, а по якій причині ви не
                  хочете забирати замовлення?{" "}
                  <RedTextColor>(відпрацьовуємо заперечення)</RedTextColor>
                </PrimaryText>

                <PrimaryText>
                  Я розумію що Ви в своєму праві, але хочу звернути Вашу увагу
                  що у випадку 2 не викуплених замовлень в нашій компанії Вам в
                  майбутньому щоб зробити замовлення потрібно буде вносити
                  частичну або повну предоплату для оформлення замовлення.
                  Підкажіть будь ласка можливо ви все таки зможете піти на
                  відділення, оглянути товар та забрати його?
                </PrimaryText>

                <PrimaryText>
                  А навіщо ви оформлювали замовлення якщо ви не планували його
                  забирати?
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>
                    Якщо каже що оформив замовлення та не планував забирати або
                    оформив просто так (без плану забрати замовлення)/ Не
                    актуально
                  </RedTextColor>
                </PrimaryText>

                <PrimaryText>
                  Хочу звернути Вашу увагу, що згідно зі статтею 190
                  Кримінального кодексу України. У разі навмисного оформлення
                  фіктивних замовлень з метою завдання збитків компанії або
                  блокування товарів, це може бути кваліфіковано як шахрайство."
                  "Відповідальність за такі дії передбачає: Штраф від 3 400 до
                  85 000 грн або Обмеження волі до 3 років Якщо Ви оформили
                  замовлення помилково або передумали, це одна справа, і ми
                  можемо знайти рішення. Але якщо це було навмисне шахрайство,
                  компанія залишає за собою право звернутися до правоохоронних
                  органів. Підкажіть, чи готові Ви все-таки отримати своє
                  замовлення або знайти інше рішення?
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>
                    Якщо забере то переходимо до завершення розмови, якщо не
                    забере то озвучуємо наступне:
                  </RedTextColor>{" "}
                </PrimaryText>

                <PrimaryText>
                  Добре, тоді компанія залишає за собою право звернутися до
                  правоохоронних органів. Дякуємо за відповідь. Якщо будуть
                  якісь запитання ви завжди можете звернутись до нас. Чудового
                  Вам дня, до побачення
                </PrimaryText>
              </div>
            </section>

            <section id="completion-agreement">
              <GreenBackgroundPrimaryTitle>
                Завершеня угоди
              </GreenBackgroundPrimaryTitle>
              <PrimaryText>
                Нагадую що замовлення зберігається на відділенні 10 днів з
                моменту прибуття. Я вас зрозумів що ви плануєте забрати
                замовлення{" "}
                <GreenTextColor>
                  (озвучуємо сроки/дату які сказав клієнт)
                </GreenTextColor>{" "}
                все вірно?
              </PrimaryText>

              <PrimaryText>
                Дякуємо за відповідь. Якщо будуть якісь запитання ви завжди
                можете звернутись до нас. Чудового Вам дня, до побачення
              </PrimaryText>
            </section>

            <section id="main-objections-handling">
              <GreenBackgroundPrimaryTitle>
                Відпрацюваня заперечень
              </GreenBackgroundPrimaryTitle>
              <div id="objections-2">
                <OrangeBackgroundPrimaryTitle>
                  Поїхав в інше місто не може поки забрати/ Захворів/В лікарні /
                  Немає грошей
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Нагадую що замовлення зберігається на відділенні 10 днів з
                  моменту прибуття. Я вас зрозумів що ви плануєте забрати
                  замовлення{" "}
                  <GreenTextColor>
                    (озвучуємо сроки/дату які сказав клієнт)
                  </GreenTextColor>
                  . Ви зможете забрати протягом цього часу?
                </PrimaryText>

                <PrimaryText>
                  <GreenTextColor>Якщо так</GreenTextColor>
                </PrimaryText>

                <PrimaryText>
                  Можливо у Вас є якісь запитання з приводу отримання?{" "}
                  <RedTextColor>
                    (відповідаємо на запитання якщо є)
                  </RedTextColor>
                </PrimaryText>
                <PrimaryText>
                  Дякуємо за відповідь. Якщо будуть якісь запитання ви завжди
                  можете звернутись до нас. Чудового Вам дня, до побачення
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>Якщо ні</RedTextColor>
                </PrimaryText>

                <PrimaryText>
                  Зателефонуйте будь ласка на віділення Нової почти/почти та
                  продовжити строк зберігання посилки щоб вона не поїхала назад
                  та у Вас була можливість забрати замовлення або можете
                  попросити співробітника нової почти/почти зробити
                  переадресацію в те місто де ви знаходитесь зараз. Або як
                  варіант можете попросити когось з близьких забрати замовлення
                  за Вас. Вам потрібно буде тільки надати номер трек коду та
                  людина яка буде забирати має мати з собою свій паспорт. Можете
                  це зробити?
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>
                    Якщо каже що не буде цього робити та ніхто не можете забрати
                    за клієнта замовлення
                  </RedTextColor>
                </PrimaryText>

                <PrimaryText>
                  Я вас зрозумів, але хочу звернути Вашу увагу що у випадку 2 не
                  викуплених замовлень в нашій компанії Вам в майбутньому щоб
                  зробити замовлення потрібно буде вносити частичну або повну
                  передоплату для оформлення замовлення. Підкажіть будь ласка
                  можливо ви все таки можете продовжити строк зберігання,
                  зробити переадресацію або хтось забрати замовлення?{" "}
                  <RedTextColor>(чекаємо відповідь)</RedTextColor>
                </PrimaryText>

                <PrimaryText>
                  Можливо у Вас є якісь запитання з приводу отримання?{" "}
                  <RedTextColor>
                    (відповідаємо на запитання якщо є)
                  </RedTextColor>
                </PrimaryText>
                <PrimaryText>
                  Дякуємо за відповідь. Якщо будуть якісь запитання ви завжди
                  можете звернутись до нас. Чудового Вам дня, до побачення
                </PrimaryText>
              </div>

              <div id="objections-3">
                <OrangeBackgroundPrimaryTitle>
                  Сумнівається в якості товару/гарантії
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Я розумію Ваше занепокоєння але хочу звернути Вашу увагу що
                  товар максимальної якості, так як ми працюємо з надійними
                  поставщиками уже дуже багато років. Тим більше що Ви нічим не
                  ризикуєте, тобто ви приходите на відділення Нової почти/почти
                  спочатку оглядаєте товар, (якщо товар електронний то клієнт
                  можете увімкнути в розетку та перевірити) тільки після цього
                  ви оплачуєте замовлення. Також на всі електронні товари ми
                  надаємо гарантію на 1 рік, у Вас буде гарантійний талон в
                  посилці і додатково у випадку якщо Ви його випадково загубили
                  то гарантія також діє по номеру телефону з якого Ви зробили
                  замовлення. Підкажіть будь ласка коли Ви можете піти на
                  віділлення та оглянути товар?{" "}
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-4">
                <OrangeBackgroundPrimaryTitle>
                  Знайшов дешевше/Дорого
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Розумію Ваше бажання заощадити, але важливо розуміти, що на
                  ринку існує три категорії товарів:
                </PrimaryText>

                <PrimaryText>
                  Бюджетні варіанти – це найдешевші товари, які зазвичай мають
                  найменший термін служби, мінімальну якість матеріалів і часто
                  не підлягають гарантійному обслуговуванню.
                </PrimaryText>

                <PrimaryText>
                  Середній клас – товари, які мають більш збалансоване
                  співвідношення ціни та якості, але не завжди можуть
                  гарантувати тривалу експлуатацію.
                </PrimaryText>

                <PrimaryText>
                  Преміум-клас – товари, які виготовлені з якісних матеріалів,
                  мають сертифікати відповідності та розраховані на довготривале
                  використання.
                </PrimaryText>

                <PrimaryText>
                  Ми працюємо виключно з преміум-класом товарів, і мінімальний
                  термін служби наших виробів – від 10 років. Вони проходять
                  перевірку якості, мають офіційну гарантію та відповідають всім
                  стандартам. Ви точно можете бути впевнені у своєму виборі!
                </PrimaryText>

                <PrimaryText>Що ми гарантуємо нашим клієнтам:</PrimaryText>
                <PrimaryText>
                  - Офіційну гарантію – якщо щось станеться з товаром, Ви
                  отримаєте сервісне обслуговування або заміну.
                </PrimaryText>

                <PrimaryText>
                  - Високоякісні матеріали – наш товар служить від 10 років і
                  більше, зберігаючи свої характеристики.
                </PrimaryText>

                <PrimaryText>
                  - Відповідність стандартам якості – ми працюємо з офіційними
                  виробниками, які сертифікують свою продукцію.
                </PrimaryText>

                <PrimaryText>
                  Можливо, спочатку здається, що дешевший товар вигідніший, але
                  якщо його доведеться змінювати через рік-два – то сума витрат
                  вийде значно більшою. Ми пропонуємо товар, який не
                  потребуватиме заміни або ремонту багато років. Це як вибір між
                  дешевими кросівками та якісним брендовим взуттям. Дешеві
                  можуть розлізтися за сезон, а преміум-клас буде служити
                  роками. Те ж саме стосується нашої продукції.
                </PrimaryText>

                <PrimaryText>
                  Тим більше що Ви нічим не ризикуєте, тобто ви приходите на
                  відділення Нової почти/почти спочатку оглядаєте товар, (якщо
                  товар електронний то клієнт можете увімкнути в розетку та
                  перевірити) тільки після цього ви оплачуєте замовлення. Також
                  на всі електронні товари ми надаємо гарантію на 1 рік, у Вас
                  буде гарантійний талон в посилці і додатково у випадку якщо Ви
                  його випадково загубили то гарантія також діє по номеру
                  телефону з якого Ви зробили замовлення. Підкажіть будь ласка
                  коли Ви можете піти на віділлення та оглянути товар?{" "}
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-5">
                <OrangeBackgroundPrimaryTitle>
                  Немає трек кода
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Ми зараз відправимо Вам повідомлення з трек кодом посилки на
                  цей номер телефону. Також підкажіть будь ласка чи є у Вас
                  можливість записати трек код замовлення?
                </PrimaryText>
              </div>

              <div id="objections-6">
                <OrangeBackgroundPrimaryTitle>
                  Не робив замовлення
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Можливо, хтось із Ваших близьких або знайомих міг оформити
                  замовлення на Ваш номер? Таке іноді трапляється. Я можу
                  уточнити деталі замовлення – назву товару та адресу доставки,
                  щоб Ви могли перевірити.
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>Якщо каже що ніхто не оформлював</RedTextColor>
                </PrimaryText>

                <PrimaryText>
                  Я розумію, що ситуації бувають різні. Але якщо замовлення було
                  оформлене навмисно, а потім Ви від нього відмовляєтеся без
                  причини, компанія має право внести цей номер у список
                  неблагонадійних клієнтів. У майбутньому для оформлення
                  замовлень може знадобитися передоплата.
                </PrimaryText>

                <PrimaryText>
                  Хочу звернути Вашу увагу, що згідно зі статтею 190
                  Кримінального кодексу України. У разі навмисного оформлення
                  фіктивних замовлень з метою завдання збитків компанії або
                  блокування товарів, це може бути кваліфіковано як шахрайство."
                  "Відповідальність за такі дії передбачає: Штраф від 3 400 до
                  85 000 грн або Обмеження волі до 3 років Якщо Ви оформили
                  замовлення помилково або передумали, це одна справа, і ми
                  можемо знайти рішення. Але якщо це було навмисне шахрайство,
                  компанія залишає за собою право звернутися до правоохоронних
                  органів. І так як ми телефонуємо на Ваш номер телефону зараз
                  то замовлення було оформлене раніше з цього номеру телефону.
                  Або хтось з рідних вказав Вас як отримувача посилки. Підкажіть
                  будь ласка можливо ви все таки зможете піти на відділення,
                  оглянути товар та забрати його?{" "}
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-7">
                <OrangeBackgroundPrimaryTitle>
                  Не дали вскрити посилку
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Розумію Ваші переживання, але згідно з умовами перевізника, Ви
                  маєте повне право оглянути посилку перед оплатою. Ймовірно,
                  працівник відділення міг бути неправильно проінформований або
                  допустив помилку. В такому випадку Ви можете повторно
                  звернутися у відділення і вимагати огляду товару перед
                  оплатою.
                </PrimaryText>

                <PrimaryText>В такій ситуації потрібно:</PrimaryText>
                <PrimaryText>
                  - При отриманні посилки чітко вказати, що Ви хочете
                  скористатися своїм правом огляду товару перед оплатою.
                </PrimaryText>
                <PrimaryText>
                  - Якщо працівники відмовляють – попросити керівника відділення
                  або показати правила на сайті перевізника.
                </PrimaryText>
                <PrimaryText>
                  - Ми зі свого боку також зателефонуємо на відділення та
                  допоможемо вирішити цю ситуацію.
                </PrimaryText>

                <PrimaryText>
                  Давайте зробимо так: Ви ще раз підете у відділення, скажете,
                  що маєте право на огляд, і переконаєтесь у якості товару. Вам
                  буде зручно це зробити сьогодні чи завтра?{" "}
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-8">
                <OrangeBackgroundPrimaryTitle>
                  Прийшов не той товар що очікував клієнт
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Я розумію, що у Вас були певні очікування щодо товару. Давайте
                  уточнимо, в чому саме відмінності? Можливо, справа в упаковці
                  або іншому дизайні, але характеристики повністю відповідають
                  замовленню?
                </PrimaryText>

                <PrimaryText>Що можна перевірити?</PrimaryText>
                <PrimaryText>- Назва моделі та її характеристики.</PrimaryText>
                <PrimaryText>
                  - Комплектацію (деякі товари можуть йти в іншій упаковці,
                  також може увімкнути в розетку електроний товра).
                </PrimaryText>
                <PrimaryText>
                  - Опис товару (можливо, клієнт очікував іншу версію товару).
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>
                    (Потрібно переконати клієнта що це саме той товар який
                    замовлял клієнт та всі характеристики відповідають
                    дійсності, для цього відкрити товар в списку товарів та
                    озвучити характеристики)
                  </RedTextColor>
                </PrimaryText>

                <PrimaryText>
                  Ви нічим не ризикуєте, тобто ви приходите на відділення Нової
                  почти/почти спочатку оглядаєте товар,{" "}
                  <RedTextColor>
                    (якщо товар електронний то клієнт можете увімкнути в розетку
                    та перевірити)
                  </RedTextColor>{" "}
                  тільки після цього ви оплачуєте замовлення. Також на всі
                  електронні товари ми надаємо гарантію на 1 рік, у Вас буде
                  гарантійний талон в посилці і додатково у випадку якщо Ви його
                  випадково загубили то гарантія також діє по номеру телефону з
                  якого Ви зробили замовлення. Підкажіть будь ласка коли Ви
                  можете піти на віділлення та оглянути товар?{" "}
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-9">
                <OrangeBackgroundPrimaryTitle>
                  Посилка в поганому стані
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Я Вас розумію, і нам важливо, щоб Ви отримали товар у
                  належному стані. У нас діє політика повернення та обміну
                  товарів згідно із Законом України "Про захист прав
                  споживачів". Хочу звернути Вашу увагу що Ви нічим не
                  ризикуєте, тобто ви приходите на відділення Нової почти/почти
                  спочатку оглядаєте товар, (якщо товар електронний то клієнт
                  можете увімкнути в розетку та перевірити) тільки після цього
                  ви оплачуєте замовлення. Також на всі електронні товари ми
                  надаємо гарантію на 1 рік, у Вас буде гарантійний талон в
                  посилці і додатково у випадку якщо Ви його випадково загубили
                  то гарантія також діє по номеру телефону з якого Ви зробили
                  замовлення. Давайте зробимо так: Ви ще раз уважно перевірите
                  товар у відділенні. Якщо все гаразд – Ви його забираєте. Якщо
                  є пошкодження – повідомте нас, і ми швидко вирішимо це
                  питання. Підкажіть будь ласка коли Ви можете піти на
                  віділлення оглянути та перевірити товар?{" "}
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-10">
                <OrangeBackgroundPrimaryTitle>
                  Відправили не на те відділення
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Розумію, що це може бути незручно, але враховуючи, що посилка
                  вже на відділенні, найшвидший спосіб отримати її – це просто
                  забрати за поточною адресою. Або у Вас є можливість самостійно
                  зробити переадресацію на інше відділення для цього потрібно
                  зателефонувати на відділення Нової пошти/пошти та попросити
                  зробити переадресацію. А ми зі свого боку щоб компенсувати Вам
                  цей дискомфорт, зробимо для Вас додаткову знижку на наступне
                  замовлення. Це наш знак подяки за Вашу лояльність! Що скажете
                  зможете зробити переадресацію або забрати на цьому відділенні?{" "}
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-11">
                <OrangeBackgroundPrimaryTitle>
                  Отримав, не працює товар
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Я розумію Ваше занепокоєння, і нам важливо, щоб Ви отримали
                  якісний товар. У Вас є можливість швидко вирішити це питання
                  через наш відділ по роботі з клієнтами.
                </PrimaryText>

                <PrimaryText>Для цього Вам потрібно:</PrimaryText>
                <PrimaryText>
                  - Перетелефонуйте на номер, який висвітився під час дзвінка.
                </PrimaryText>
                <PrimaryText>
                  - Після з’єднання з автовідповідачем натисніть кнопку 3.
                </PrimaryText>
                <PrimaryText>
                  - Ваш дзвінок буде переадресовано у відділ по роботі з
                  клієнтами.
                </PrimaryText>
                <PrimaryText>
                  - Там Ви зможете оформити повернення або обмін товару.
                </PrimaryText>

                <PrimaryText>
                  Це потрібно зробити з Понеділок – П’ятниця з 08:00 – 17:00 так
                  як в цей час працює даний відділ. Я впевнений, що наша команда
                  швидко допоможе Вам вирішити це питання. Будь ласка,
                  зателефонуйте за вказаною схемою у зручний для Вас час!
                  Чудового Вам дня, до побачення
                </PrimaryText>
              </div>

              <div id="objections-12">
                <OrangeBackgroundPrimaryTitle>
                  Не поклали подарунок
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Вибачаюсь за доставлені незручності. Ми цінуємо наших клієнтів
                  і хочемо, щоб Ви залишилися задоволені. Ваше замовлення вже
                  чекає на Вас у відділенні – заберіть його, а подарунок ми
                  відправимо Вам додатково!
                </PrimaryText>

                <PrimaryText>
                  Це наш спосіб подякувати Вам за вибір нашої компанії. Ви
                  зможете забрати замовлення сьогодні чи завтра?
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>

              <div id="objections-13">
                <OrangeBackgroundPrimaryTitle>
                  Не актуально:
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Розумію, що ситуація могла змінитися, і товар зараз може
                  здаватися Вам неактуальним. Але Ви ж оформили замовлення, коли
                  воно Вам було потрібне, і ми оперативно його відправили. Хочу
                  звернути Вашу увагу, що у разі двох невикуплених замовлень у
                  майбутньому для оформлення нових покупок може знадобитися
                  передоплата. Ви можете зробити комусь із рідних чудовий
                  подарунок адже попереду багато свят.
                </PrimaryText>

                <PrimaryText>
                  Щоб уникнути цих обмежень, можливо, все ж варто отримати
                  посилку?
                  <RedTextColor>
                    (переходимо до завершення розмови після відповіді)
                  </RedTextColor>{" "}
                </PrimaryText>
              </div>
            </section>
          </div>

          <PrimaryList>
            <PimaryItem>
              <ScriptNavLink href="#greeting">Основа.</ScriptNavLink>
            </PimaryItem>

            <SecondaryList>
              <SecondaryItem>
                <ScriptNavLink href="#objections-1">
                  Замовив без плану викупу
                </ScriptNavLink>
              </SecondaryItem>
            </SecondaryList>

            <PimaryItem>
              <ScriptNavLink href="#completion-agreement">
                Завершення розмови
              </ScriptNavLink>
            </PimaryItem>

            <PimaryItem>
              <ScriptNavLink href="#main-objections-handling">
                Відпрацюваня заперечень
              </ScriptNavLink>
            </PimaryItem>

            <SecondaryList>
              <SecondaryItem>
                <ScriptNavLink href="#objections-2">
                  Поїхав в інше місто не може поки забрати/ Захворів/В лікарні /
                  Немає грошей
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-3">
                  Сумнівається в якості товару/гарантії
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-4">
                  Знайшов дешевше/Дорого
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-5">
                  Немає трек кода
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-6">
                  Не робив замовлення
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-7">
                  Не дали вскрити посилку
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-8">
                  Прийшов не той товар що очікував клієнт
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-9">
                  Посилка в поганому стані
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-10">
                  Відправили не на те відділення
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-11">
                  Отримав, не працює товар
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-12">
                  Не поклали подарунок
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-13">
                  Не актуально
                </ScriptNavLink>
              </SecondaryItem>
            </SecondaryList>
          </PrimaryList>
        </div>
      </Main>
      <ScrollToTopButton />
      <Outlet />
    </div>
  );
};
