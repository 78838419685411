import { Main } from "../../components/GlobalComponentsAndStyles/Main";
import {
  PrimaryText,
  RedTextColor,
  OrangeBackgroundPrimaryTitle,
  GreenBackgroundPrimaryTitle,
  BlueTextColor,
  GreenTextColor,
} from "../../components/GlobalComponentsAndStyles/GlobalStyles.styled";
import { Outlet } from "react-router-dom";
import {
  PrimaryList,
  SecondaryList,
  PimaryItem,
  SecondaryItem,
  ScriptNavLink,
} from "./Script.styled";
import { ScrollToTopButton } from "../../components/ScrollToTopButton/ScrollToTopButton";

export const Script = () => {
  return (
    <div style={{ userSelect: "none" }}>
      <Main>
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ width: "70%" }}>
            <section id="greeting">
              <GreenBackgroundPrimaryTitle>
                Привітання, Виявлення потреб, Презентація.
              </GreenBackgroundPrimaryTitle>
              <PrimaryText>
                Доброго дня <GreenTextColor>(Ім'я клієнта)</GreenTextColor>,
                мене звати <GreenTextColor>(Ваше Ім'я)</GreenTextColor> я
                старший менеджер компанії DCM{" "}
                <GreenTextColor>(Дисконт центр)</GreenTextColor>.{" "}
                <BlueTextColor>
                  Дзвоню повідомити про те, що у Вас накопичилося 2799 грн.
                  бонусів за Ваші покупки у магазинах та супермаркетах
                  електроніки!{" "}
                </BlueTextColor>
                Моє завдання розповісти Вам, як їх можна використати! Сьогодні
                Ви можете скористатися своїми бонусами та отримати до 60% знижки
                на придбання товарів з таких категорій, як: Для кухні та
                будинку, для автомобіля, для відпочинку, для чоловіків, жінок та
                товари для дітей.
              </PrimaryText>
              <PrimaryText>
                -<GreenTextColor>(Ім'я клієнта)</GreenTextColor> Підкажіть будь
                ласка з якої категорії розпочнемо?{" "}
                <RedTextColor>Чекаємо відповідь</RedTextColor>
              </PrimaryText>

              <div id="objections-32">
                <OrangeBackgroundPrimaryTitle>
                  Не планував / Не потрібно / Не цікавить
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, я розумію, що
                  ви не планували цю покупку зараз, але хочу звернути вашу увагу
                  на те, що багато клієнтів купують ці товари за повною
                  вартістю, а ви можете придбати цей товар зі знижкою.
                  Наприклад, пилосос ROYALS Berg GT-1605 коштує 3288 грн, а вам
                  доступний з урахуванням ваших бонусів всього за 2149 грн,
                  тобто ваша економія складає 1139 грн. Весь товар німецької
                  якості, і на нього надається гарантія на 1 рік, мінімальний
                  строк служби від 10 років. Ви оплачуєте замовлення при
                  отриманні та після перевірки на відділенні Нової пошти. Також
                  хочу звернути вашу увагу, що замовлення зберігається на
                  відділенні 10 днів після прибуття. Що скажете? Яка категорія
                  вас найбільше зацікавила?
                </PrimaryText>
              </div>

              <PrimaryText>
                В даній категорії рекомендую звернути увагу на такі товари
                <RedTextColor>
                  {" "}
                  (перераховуємо кілька найпопулярніших товарів з короткою
                  характеристикою).
                </RedTextColor>{" "}
                Скажіть, про який товар розповісти докладніше?{" "}
                <RedTextColor>(Чекаємо відповідь)</RedTextColor>
              </PrimaryText>

              <PrimaryText>
                Відмінний вибір! Більшість наших клієнтів таки зупиняють свій
                вибір саме на цьому товарі! Впевнений(а) Ви залишитеся
                задоволені!{" "}
                <RedTextColor>
                  {" "}
                  (Далі детально описуємо товар, його переваги та особливості)
                </RedTextColor>
              </PrimaryText>
            </section>

            <section id="additional-offers">
              <GreenBackgroundPrimaryTitle>
                Пропозиція додаткового товару.
              </GreenBackgroundPrimaryTitle>
              <PrimaryText>
                <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, Ви зробили
                чудовий вибір{" "}
                <GreenTextColor>
                  (називаємо товар який обрав клієнт)
                </GreenTextColor>{" "}
                пропоную Вам на залишок бонусів підібрати товари, що Вас
                цікавлять. Ваша знижка 60% все також діє, зможете ще більше
                заощадити і зробити вигідну покупку. Можна підібрати щось для
                всієї родини або на подарунок близьким/друзям. Вибрати товари
                можна з тих же категорій: Для кухні та будинку, для автомобіля,
                для відпочинку, для чоловіків, жінок та товари для дітей. Яку
                категорію розглянемо?
              </PrimaryText>
            </section>

            <section id="objections-handling">
              <GreenBackgroundPrimaryTitle>
                Відпрацювання заперечень до додаткових товарів.
              </GreenBackgroundPrimaryTitle>
              <div id="objections-1">
                <OrangeBackgroundPrimaryTitle>
                  А скільки у мене бонусів? Скільки товарів я можу купити?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, , за
                  правилами акції Ви можете придбати до трьох товарів в одному
                  чеку. І незалежно від того, яка кількість бонусів у Вас
                  залишилася - кожен з товарів ви купуєте зі знижкою до 60%.
                </PrimaryText>
              </div>

              <div id="objections-2">
                <OrangeBackgroundPrimaryTitle>
                  Мені не потрібно кілька товарів
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, Безумовно, на
                  перший погляд кілька товарів можуть здатися не зовсім до речі,
                  але щоб позбавитися сумнівів, не зайвим буде розібратися в
                  їхніх перевагах. Тим більше, тільки сьогодні Ви маєте
                  можливість придбати товари за півціни та скористатися Вашими
                  бонусними накопиченнями. Що скажете, який з додаткових товарів
                  хотіли б придбати?
                </PrimaryText>
              </div>

              <div id="objections-3">
                <OrangeBackgroundPrimaryTitle>
                  Дорого
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Так <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, Безумовно
                  якісні продукти коштують певних коштів. Щоб отримати максимум
                  за мінімальні гроші я пропоную придбати товари за півціни
                  сьогодні при цьому скориставшись своїми бонусними
                  накопиченнями. Це зручніше і вигідніше, ніж купувати кожен
                  продукт окремо. Отже, підкажіть, будь ласка, визначилися з
                  додатковими товарами?
                </PrimaryText>
              </div>

              <div id="objections-4">
                <OrangeBackgroundPrimaryTitle>
                  Якість
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Справді, я розумію Ваші сумніви. Наша компанія на ринку вже 7
                  років, ми надаємо Вам якісні товари Німецького, Турецького та
                  Китайського виробництва . Можемо надати сертифікат на даний
                  товар{" "}
                  <RedTextColor>
                    (при запиті клієнта можемо покласти до посилки)
                  </RedTextColor>
                  . Тим більше, що зараз Ви нічого не оплачуєте, а вносите
                  оплату лише за фактом отримання та коли уважно ознайомитеся з
                  товаром. В цьому випадку{" "}
                  <GreenTextColor>(Ім'я клиєнта)</GreenTextColor>, вам підійде
                  такий варіант?
                </PrimaryText>
              </div>

              <div id="objections-5">
                <OrangeBackgroundPrimaryTitle>
                  Подумаю/Пораджусь
                </OrangeBackgroundPrimaryTitle>

                <PrimaryText>
                  Звичайно, Ви можете подумати/порадитись. Якщо у Вас залишилися
                  питання я на них відповім. Що саме Вас бентежить: ціна чи
                  якість?
                </PrimaryText>
              </div>

              <div id="objections-6">
                <OrangeBackgroundPrimaryTitle>
                  Пораджусь (уточнюючі питання)
                </OrangeBackgroundPrimaryTitle>

                <PrimaryText>
                  Підкажіть, яка інформація Вам потрібна для того, щоб ухвалити
                  самостійно рішення?
                </PrimaryText>
                <PrimaryText>
                  У Вас питання щодо необхідності товару чи можливостей бюджету?
                </PrimaryText>
              </div>
            </section>

            <section id="completion-agreement">
              <GreenBackgroundPrimaryTitle>
                Завершеня угоди
              </GreenBackgroundPrimaryTitle>
              <PrimaryText>
                Підкажіть будь ласка Ваші ПІБ. Область, місто та відділення
                нової пошти, де Вам зручно отримати товар?
              </PrimaryText>
              <PrimaryText>
                Замовлення складається з{" "}
                <RedTextColor>
                  (озвучуємо основний та додаткові товари)
                </RedTextColor>
                . Загальна сума до оплати <RedTextColor>(_____)</RedTextColor>,
                нагадаю, що посилку наша компанія відправляє післяплатою.
              </PrimaryText>
              <PrimaryText>
                + Вартість доставки згідно з тарифами НП. Посилка надійде
                протягом 2-3 днів, перед оплатою товару ви можете розкрити його
                подивитися і перевірити на працездатність, тільки писля цього
                оплачуєте.
              </PrimaryText>

              <PrimaryText>
                І якщо ви зможете викупити товар протягом 3-х днів з моменту
                прибуття на пошту – автоматично станете учасником розіграшу
                MacBook Pro. Розіграш проводиться в останній день кожного
                місяця. Для отримання виграшу необхідно зберегти чек із Нової
                Пошти. Про результати розіграшу Вам повідомлять SMS
                повідомленням або зв'яжиться наш оператор
                <RedTextColor>(ім'я клієнта)</RedTextColor>, чи залишилися у Вас
                питання? Чекайте на посилку, Всього доброго і Хорошого вам дня!
              </PrimaryText>
            </section>

            <section id="main-objections-handling">
              <GreenBackgroundPrimaryTitle>
                Відпрацюваня заперечень
              </GreenBackgroundPrimaryTitle>
              <div id="objections-6">
                <OrangeBackgroundPrimaryTitle>
                  Як можна використати бонуси?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, за умовами
                  акції у Вас є знижка до 60% вартості товару, решту оплачуєте
                  тільки при отриманні товару на Новій пошті. В наявності є
                  товари для: кухні, будинку, автомобіля та електроніка. Що
                  викликає найбільший інтерес? Розкажу більш докладніше
                </PrimaryText>
              </div>

              <div id="objections-7">
                <OrangeBackgroundPrimaryTitle>
                  Звідки я маю ці бонуси?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, завдяки Вашим
                  покупкам у магазинах та супермаркетах електроніки, що входять
                  до нашої оптової мережі – накопичувалися бонуси, які тепер Ви
                  можете витратити як знижку на придбання товарів з Німеччини,
                  Туречини або Китаю, постачальниками яких ми є. Знижка до 60%
                  діє на товари: Для кухні та будинку, для автомобіля, для
                  відпочинку, для чоловіків, жінок та товари для дітей.{" "}
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, підкажіть,
                  яка категорія для Вас найцікавіша?
                </PrimaryText>
              </div>

              <div id="objections-8">
                <OrangeBackgroundPrimaryTitle>
                  З якої ви компанії?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Наша компанія називається DCM{" "}
                  <GreenTextColor>(Discount Commerce Mart)</GreenTextColor>, ми
                  займаємося постачанням товарів з Європи та Азії в Україну для
                  багатьох магазинів техніки. Коли Ви здійснили покупку в одному
                  з магазинів техніки, у Вас накопичилися бонуси від нашої
                  компанії. Зараз Ви можете скористатися своїм бонусом та
                  придбати наступні категорії товарів: Для кухні та дому, для
                  автомобіля, для відпочинку, для чоловіків, жінок та товари для
                  дітей.
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, підкажіть,
                  яка категорія для Вас найцікавіша?
                </PrimaryText>
              </div>

              <div id="objections-9">
                <OrangeBackgroundPrimaryTitle>
                  Доставка платна?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, доставка
                  здійснюється згідно з тарифами Нової пошти. Що скажете,
                  оформляємо замовлення?
                </PrimaryText>
              </div>

              <div id="objections-10">
                <OrangeBackgroundPrimaryTitle>
                  Звідки у вас мій номер?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, анкетні дані
                  та розмір накопичених бонусів, надають нам наші партнери{" "}
                  <GreenTextColor>
                    (Розетка, Комфі, Ельдорадо авто-магазини, продуктові мережі,
                    моб. оператори, аптеки тощо)
                  </GreenTextColor>
                  , але не надають нам інформацію, на замовлення, завдяки яким у
                  Вас сьогодні є знижка.
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, впевнений
                  <GreenTextColor>(a)</GreenTextColor> Вас зацікавить
                  пропозиція, продовжимо?
                </PrimaryText>
              </div>

              <div id="objections-11">
                <OrangeBackgroundPrimaryTitle>
                  Товар якісний?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, безперечно.
                  Ми отримуємо весь товар безпосередньо від виробника, товар
                  проходить кілька перевірок перед постачанням та безпосередньо
                  перед відправкою клієнту. Це вигідна пропозиція. Оформляємо
                  замовлення/Продовжимо?
                </PrimaryText>
              </div>

              <div id="objections-12">
                <OrangeBackgroundPrimaryTitle>
                  Чому така знижка?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, це програма
                  лояльності для активних клієнтів, завдяки якій у Вас є
                  можливість придбати товар фактично за собівартістю
                  безпосередньо від виробника. Все це завдяки отриманими Вами
                  бонусам. Дуже вигідна пропозиція. Оформляємо Ваше
                  замовлення/Продовжимо?
                </PrimaryText>
              </div>

              <div id="objections-13">
                <OrangeBackgroundPrimaryTitle>
                  У вас є гарантія?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, ми
                  обов'язково надаємо нашим клієнтам гарантію на 1 рік так як
                  "DCM" офіційна компанія, яка зарекомендувала себе на ринку, ми
                  цінуємо наших клієнтів та репутацію. У разі виявлення
                  несправності Ви завжди зможете звернутися за номером телефону,
                  що вказаний у гарантійному талоні.
                </PrimaryText>
              </div>

              <div id="objections-14">
                <OrangeBackgroundPrimaryTitle>
                  Якщо товар зламається?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, якщо
                  отримавши товар, Ви побачите, що упаковка або сам товар
                  пошкоджені, згідно із Законом про захист Прав Споживачів
                  протягом 14 днів можете повернути покупку, зробити обмін чи
                  ремонт товару. Просто зателефонуйте на номер телефону, який
                  вказаний у Вашому гарантійному талоні – і Вашу проблему
                  вирішать.
                </PrimaryText>
              </div>

              <div id="objections-15">
                <OrangeBackgroundPrimaryTitle>
                  Звідки буде доставка?
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, так як у нас
                  є кілька складів по всій Україні, Вам відправлять товар з
                  найближчого складу, де товар є в наявності. Оформляємо
                  замовлення/Продовжимо?
                </PrimaryText>
              </div>

              <div id="objections-16">
                <OrangeBackgroundPrimaryTitle>
                  Я нічого не купував у магазинах електроніки.{" "}
                  <RedTextColor>
                    (спочатку відпрацюйте Звідки у мене ці бонуси?)
                  </RedTextColor>
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Ваш номер телефону зареєстрований по ряду дисконтних карток та
                  покупок, які оформлялися в магазинах техніки та супермаркетах
                  електроніки. Ви купували протягом трьох років наші товари: для
                  кухні та будинку, відпочинку, автомобіля, дітей. І таким чином
                  ви накопичували бонуси в нашій бонусній програмі, якщо
                  дозволите, я проконсультую і назву вам найпопулярніші
                  категорії: багато часу це не займе, буквально 2-3 хвилини,
                  тому що я впевнений<GreenTextColor>(а)</GreenTextColor>, що у
                  нас є товари, які вас точно зацікавлять, гаразд?
                </PrimaryText>
              </div>

              <div id="objections-17">
                <OrangeBackgroundPrimaryTitle>
                  Я не маю часу вас слухати. Мені незручно розмовляти!
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, звичайно,
                  можливо вам зараз не зовсім зручно розмовляти, але бонусна
                  пропозиція багато часу у вас не займе, буквально 2-3 хвилини
                  вашого часу, дозвольте я вас швидко ознайомлю, добре?
                </PrimaryText>
              </div>

              <div id="objections-18">
                <OrangeBackgroundPrimaryTitle>
                  Давайте обговоримо пізніше/Мені ніколи:
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  1. <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, добре, а
                  загалом це питання для Вас зараз актуальне, вірно?
                </PrimaryText>

                <PrimaryText>
                  2. І все ж, раз я вам додзвонився
                  <GreenTextColor>(лася)</GreenTextColor> дозвольте я вам
                  розповім детальніше про ці бонуси, це займе всього 3-5 хвилин
                  вашого часу, а ви вже самі приймете рішення - що вам потрібно
                  придбати в будинок або на кухню, так як у нас представлено
                  багато цікавих товарів, які можуть вас зацікавити. Добре?
                </PrimaryText>
              </div>

              <div id="objections-20">
                <OrangeBackgroundPrimaryTitle id="objections-20">
                  Не хочу використовувати бонуси, можете їх анулювати / Я хочу
                  певний товар, а якщо немає його, то нічого не треба
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, Безумовно, Ви
                  маєте право утриматися. При цьому Ви більше трьох років
                  накопичували ці бонуси. Давайте розповім про умови бонусної
                  програми, це Вас ні до чого не зобов'язує, знатимете всю
                  інформацію, щоб не прогаяти вигідну пропозицію. Зараз Ви
                  можете скористатися своїми бонусами та придбати наступні
                  категорії товарів: Для кухні та дому, для автомобіля, для
                  відпочинку, для чоловіків, жінок та товари для дітей.
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, підкажіть,
                  яка категорія для Вас найцікавіша?
                </PrimaryText>
              </div>

              <div id="objections-21">
                <OrangeBackgroundPrimaryTitle id="objections-21">
                  Я хочу певний товар, якщо його немає – нічого не потрібне.
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, розумію Вас,
                  проте, хочу поінформувати, що у нас досить багато корисних та
                  цікавих товарів, які Ви можете придбати за вигідною вартістю.
                  Дозвольте, я проінформую Вас детальніше, впевнений
                  <GreenTextColor>(а)</GreenTextColor>, Вас щось зацікавить!
                </PrimaryText>
              </div>

              <div id="objections-22">
                <OrangeBackgroundPrimaryTitle>
                  Не знаю, що мені робити з бонусами.
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім’я клієнта)</GreenTextColor>, розумію вас.
                  Як фахівець, зараз детальніше розповім Вам про бонусну
                  програму. І Ви вже прийміть собі остаточне рішення. Чи згодні?
                </PrimaryText>
              </div>

              <div id="objections-23">
                <OrangeBackgroundPrimaryTitle>
                  Дорого/Знайшли в іншому магазині дешевше.
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  1. Розумію Вас,{" "}
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, Ціна може
                  здатися вам великою. Проте, хочу сказати, що ми пропонуємо
                  якісний товар із гарантійним талоном та можливістю перевірити
                  товар у відділенні НП перед оплатою. Тим більше, Ви розумієте
                  - якісний товар не може коштувати мало, а Ви маєте можливість
                  придбати продукцію від перевірених виробників практично за
                  собівартістю, за рахунок накопичених бонусів. Пропозиція
                  дійсно вигідна. Якого рішення Ви дійшли? Оформляємо Ваше
                  замовлення?
                </PrimaryText>
                <PrimaryText>
                  2. Так, звичайно, розумію вас,{" "}
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, оскільки сам
                  <GreenTextColor>(а)</GreenTextColor> у минулому намагався
                  скрізь заощадити. При цьому я стикався
                  <GreenTextColor>(лась)</GreenTextColor> з такою проблемою, як
                  поломка дешевшого товару і в результаті мені доводилося
                  повторно витрачати свої фінанси і купувати новий, якісніший
                  товар, набагато дорожче. Впевнений
                  <GreenTextColor>(а)</GreenTextColor>, ви теж з таким
                  стикалися, так? Саме тому я можу запевнити та гарантувати
                  якість нашого товару, який прослужить довше, завдяки своїм
                  характеристикам та перевагам{" "}
                  <GreenTextColor>
                    (йде перерахування переваг та вигод товару)
                  </GreenTextColor>
                  . Яке рішення Ви приняли?
                </PrimaryText>
                <PrimaryText>
                  3. <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, я вас
                  чудово розумію. Насправді, я вже мав кілька клієнтів, які, як
                  і Ви, вважали вартість товару занадто високою. Але потім вони
                  порівняли нашу пропозицію з більш дешевими аналогами і
                  переконалися в тому, що набагато вигідніше 1 раз придбати
                  якісний товар від перевірених виробників і більше не
                  витрачатися на придбання аналогів при поломці дешевшого
                  товару. Дане вкладення гарантує Вам якість та результат, на
                  який Ви розраховуєте порівняно з дешевими аналогами.
                  Оформляємо Ваше замовлення?
                </PrimaryText>
                <PrimaryText>
                  4. Дуже важливо, що Ви звернули на це увагу,{" "}
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, для акційної
                  пропозиції ми спеціально вибираємо дорогий товар, щоб наші
                  клієнти мали змогу придбати його за більш доступною вартістю.
                  Зверніть увагу на повну вартість{" "}
                  <RedTextColor>(ОЗВУЧУЄМО ЦІНУ У МАГАЗИНІ)</RedTextColor> і на
                  ціну з бонусами - різниця велика. Тож пропозиція справді
                  вигідна. Перейдемо до оформлення?
                </PrimaryText>
              </div>

              <div id="objections-24">
                <OrangeBackgroundPrimaryTitle>
                  Я не можу придбати товар, не бачачи його.
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, я Вас
                  розумію, У будь-якому випадку на відділенні Нової Пошти Ви
                  спочатку оглядаєте товар, все уважно перевіряєте та тільки
                  потім оплачуєте! Запевняю Вас, що товар Вам сподобається,
                  гарна якість, перевірений виробник, у нас у продажу він уже
                  понад 2 роки. Тому можу Вам точно сказати, що купивши – не
                  пошкодуєте. Ще й із такою знижкою. Додаємо до замовлення?
                </PrimaryText>
              </div>

              <div id="objections-25">
                <OrangeBackgroundPrimaryTitle>
                  Скиньте посилання на сайт, де я можу подивитися все і вибрати.
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, Наш сайт
                  доступний тільки оптовим клієнтам{" "}
                  <GreenTextColor>
                    (Комфі, Ельдорадо, Епіцентр, автомагазини, продуктові
                    мережі, моб.оператори, аптеки та ін.)
                  </GreenTextColor>{" "}
                  в зв'язку з тим що у багатьох людей декілька номерів
                  телефонів, і наприклад якщо Ви вкажете інший номер з якого не
                  робили замовлень то бонусів не буде і Ви не зможете ними
                  скористатися. А якщо надавати доступ всім до цього сайту то
                  ніякого привілегій з Ваших бонусів не буде так як кожен зможе
                  брати товари по знижці. Тому продаж товару для фізичних осіб ми
                  здійснюємо в телефонному режимі, і щоб у Вас була можливість
                  перевірити Ваш товар - ми відправляємо замовлення наложеним
                  платежем. Тобто Ви можете повністю оглянути
                  товар на відділені Нової почти, перевірити як він працює і
                  тільки після Ви його оплачуєте. Наприклад зараз Ви можете
                  скористатися знижкою на...{" "}
                  <RedTextColor>
                    (Розповісти про 1 із товарів, та озвучити ціну зі знижкою та
                    без знижки).
                  </RedTextColor>
                </PrimaryText>
              </div>

              <div id="objections-26">
                <OrangeBackgroundPrimaryTitle>
                  Немає грошей
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  1. Дійсно, можливо, ви не розраховували на сьогоднішнє
                  придбання і добре що ви це сказали, я розумію ви просто не
                  очікували сьогоднішнього дзвінка, вірно? Якщо дозволите
                  приділити Вам пару хвилин, хотів
                  <GreenTextColor>(ла)</GreenTextColor> відзначити переваги цієї
                  пропозиції <GreenTextColor>(товару)</GreenTextColor>. Ця
                  модель перевершує всі очікування завдяки своїм
                  характеристикам, це гарантує тривалий термін і довгострокове
                  використання, тобто вам не знадобиться купувати через рік-два
                  новий <GreenTextColor>(товар)</GreenTextColor>. При цьому
                  сьогодні ви заощадите чималі гроші. Продовжимо оформлення?
                </PrimaryText>

                <PrimaryText>
                  2. <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, річ у
                  тому, що сьогодні останній день дії бонусів, які Ви
                  накопичували протягом трьох років. Якщо Ви ними не
                  скористаєтеся – вони будуть анульовані. Щоб Ви не втратили
                  можливість їх використовувати, дозвольте назвати Вам
                  найпопулярніші категорії товарів, а Ви оберете те, що Вам
                  найбільше подобається.
                </PrimaryText>
              </div>

              <div id="objections-27">
                <OrangeBackgroundPrimaryTitle>
                  Я подумаю
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Підкажіть, над чим хочете подумати? Що зупиняє Вас від
                  замовлення на даний момент: ціна чи якість? Я можу відповісти
                  на всі ваші запитання.
                </PrimaryText>
                <PrimaryText>
                  1. <GreenTextColor>(Ім'я клієнта)</GreenTextColor>розумію що
                  можливо є питання, які вас більше цікавлять. Але загалом, як
                  вам?
                </PrimaryText>
                <PrimaryText>
                  2. <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, загалом
                  вам підходить даний товар чи є якісь моменти, які ви хочете
                  уточнити?
                </PrimaryText>
                <PrimaryText>
                  3. <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, до речі
                  якщо порівнюватимете наші{" "}
                  <GreenTextColor>(або цей)</GreenTextColor> товар
                  <GreenTextColor>(и)</GreenTextColor>, ще потрібно врахувати
                  такий момент - що не всі товари в інтернеті зроблені з:
                  полімеру{" "}
                  <GreenTextColor>
                    (удароміцний, не дешевий пластик)
                  </GreenTextColor>
                  , незвичайний алюміній{" "}
                  <GreenTextColor>(а, якісна нержавіюча сталь)</GreenTextColor>,
                  не антипригарне покриття{" "}
                  <GreenTextColor>
                    ( яке злізе через 1-2 роки, а п'ятишарове покриття, яке
                    прослужить 10-13 років)
                  </GreenTextColor>
                  . Тобто так, ви можете купити дешевше, але найчастіше з такими
                  товарами дуже часто щось трапляється. Є великий ризик, що дуже
                  швидко зіпсується продукція і в будь-якому випадку ви тільки
                  переплатите в короткий проміжок часу.
                </PrimaryText>
              </div>

              <div id="objections-28">
                <OrangeBackgroundPrimaryTitle>
                  Нині свята, карантин, військовий стан, складності та криза. Не
                  до вас
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, у нашій
                  країні свята <GreenTextColor>(труднощі)</GreenTextColor>{" "}
                  щодня. Зараз важливо зрозуміти чи підходить Вам загалом такий
                  товар, за такою вигідною пропозицією? І найголовніше це
                  безпека придбання: ви двічі будете застраховані, перші 14
                  днів, і цілий рік із гарантійним талоном.
                </PrimaryText>
              </div>

              <div id="objections-29">
                <OrangeBackgroundPrimaryTitle>
                  У вас мають бути мої дані, якщо у вас є мій номер телефону
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Так, звичайно, ваші дані є в базі нашої компанії, при цьому
                  хочу зазначити, що в компанії діє автонабір клієнтів, і ми не
                  маємо доступу до вашого номера телефону. Наша компанія зробила
                  це спеціально для захисту даних покупця. Однак наші партнери
                  надають нам ваші анкетні дані. Моє завдання — уточнити ці
                  дані, можливо, ви змінили прізвище, робили замовлення на іншу
                  особу або змінили місце проживання у зв'язку з війною.
                </PrimaryText>
              </div>

              <div id="objections-30">
                <OrangeBackgroundPrimaryTitle>
                  Я хочу порадитися з чоловіком/дружиною
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Підкажіть, а чому Ви хочете порадитись саме з цією людиною?
                  Він<GreenTextColor>(вона)</GreenTextColor> може дати Вам
                  слушну/якісну пораду щодо цього товару, правильно я розумію?
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>
                    Якщо клієнт не каже, що йому така ж важлива ціна,
                    промовляємо наступне
                  </RedTextColor>
                </PrimaryText>
                <PrimaryText>
                  А може бути що для Вас є важливою також фінансова сторона?
                </PrimaryText>
                <PrimaryText>
                  У такому разі, можливо, я можу також проконсультувати Вашого
                  чоловіка/Вашу дружину? Справа в тому, що Ви вже маєте повний
                  обсяг інформації, яку я Вам надав
                  <GreenTextColor>(ла)</GreenTextColor>, і розумієте чому цей
                  продукт коштує своїх грошей. Можу також докладно розповісти
                  все Вашому<GreenTextColor>(Вашій)</GreenTextColor>{" "}
                  чоловікові/дружині і Ви так само разом ухвалите рішення.{" "}
                </PrimaryText>

                <PrimaryText>
                  <RedTextColor>
                    Клієнт відмовляється від консультації чоловіка / дружини
                  </RedTextColor>
                </PrimaryText>
                <PrimaryText>
                  У такому разі пропоную передзвонити Вам протягом
                  сьогоднішнього дня, оскільки сьогодні останній день бонусної
                  пропозиції. Щоб Ви встигли скористатися ним та придбати те, що
                  Вам сподобалося.
                </PrimaryText>
                <PrimaryText>
                  Хочу порадитися з чоловіком/дружиною:{" "}
                </PrimaryText>
                <PrimaryText>
                  1. <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, досвід
                  мені показав, що 89% позапланових придбань чоловік/дружина
                  лише схвалює, тому що в будинку це потрібний елемент. І у
                  великому відсотковому співвідношенні дане придбання допоможе
                  вам у побуті, тому що це звільнить ваш час та час
                  дружини/чоловіка{" "}
                  <GreenTextColor>
                    (все залежить від товару, який зацікавив клієнта)
                  </GreenTextColor>
                  .
                </PrimaryText>
                <PrimaryText>
                  1. Так, звичайно{" "}
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, просто
                  відразу хочу відзначити один важливий момент:
                </PrimaryText>
                <PrimaryText>
                  <BlueTextColor>Якщо покупець чоловік:</BlueTextColor>{" "}
                  впевнений<GreenTextColor>(а)</GreenTextColor> що ви погодитеся
                  зі мною, всі ми чудово знаємо і розуміємо що жінка в будинку
                  господиня, і що найчастіше саме вона бачить, що потрібно
                  придбати в будинок, і чого не вистачає. Повірте моєму досвіду,
                  якщо ви помітите відсутність потрібної техніки на кухні або в
                  будинку і придбаєте її без згоди дружини - вашій дружині буде
                  набагато приємніше, тому що вона тільки переконається в тому,
                  що не все в цьому будинку тримається на ній. І гарантую вам -
                  вона буде більш ніж приємно здивована.
                </PrimaryText>
                <PrimaryText>
                  <BlueTextColor>Якщо покупець жінка:</BlueTextColor> всі ми
                  знаємо, що саме дівчата і жінки господині в будинку. І купуючи
                  кухонну техніку ви купуєте це тільки в сімейних цілях, адже
                  якщо ви купуєте блендер, м'ясорубку або сковорідки, хто
                  готуватиме на них? Та чоловіки не завжди об'єктивно розцінюють
                  побутові покупки, тому що вони цим не користуються, наведу вам
                  один приклад, ваш чоловік має машину? Ось скажіть мені, чи він
                  радився б з вами якщо потрібно було заправити машину, поміняти
                  масло або змінити зимову гуму? Звичайно ж ні, він би відразу
                  це зробив, так як часто використовує машину, і за всією
                  технікою потрібен догляд. Так і для вас, адже саме ви готуєте
                  їжу, то чому не готувати її швидше та смачніше?
                </PrimaryText>
              </div>

              <div id="objections-31">
                <OrangeBackgroundPrimaryTitle>
                  Подивлюся ціну в інтернеті
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  1. Так, звичайно,{" "}
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, хочу тільки
                  сказати, щоб Ви обов'язково шукали посилання на сайт із
                  оригінальним товаром. Так як в мережі дуже багато підробок, Ви
                  можете знайти подібний товар дешевше, але набагато гірше за
                  якістю. Тож обов'язково шукайте виключно оригінал.
                </PrimaryText>
                <PrimaryText>
                  <RedTextColor>
                    (Можемо це зробити зараз, під час консультації,
                    переконаєтеся в тому, що наша пропозиція справді
                    приваблива).
                  </RedTextColor>
                </PrimaryText>
                <PrimaryText>
                  2. Я розумію Ваше бажання подивитися ціни в інтернеті, проте
                  можу запевнити, що вказані там ціни та якість товарів не
                  завжди відповідають дійсності. Ви зможете подивитися до
                  оплати, перевірити на працездатність і отримати гарантію на 1
                  рік. А ще можу обіцяти, що купуючи нашу продукцію, Ви
                  повернетесь до нас знову за іншими товарами, як і багато наших
                  клієнтів. Що скажете, розповісти вам про товар?
                </PrimaryText>
              </div>

              <div id="objections-33">
                <OrangeBackgroundPrimaryTitle>
                  Переведіть на ЗСУ
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  Дякую <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, за Ваше
                  бажання підтримати ЗСУ! На жаль, ми не маємо технічної
                  можливості переказати їх на благодійні рахунки та згідно з
                  умовами програми бонуси може використати тільки клієнт і лише
                  на знижку при покупці товарів. Але зараз ви можете
                  скористатися вашими бонусами для придбання товарів
                  преміум-якості зі знижкою до 60%! Це чудова можливість
                  отримати вигідну покупку за мінімальну ціну.
                </PrimaryText>

                <PrimaryText>
                  Наприклад Ви можете скористатися бонусами на пилосос ROYAL
                  BERG GT-1604 3000W потужності, тож пил і бруд просто не мають
                  шансів. По-друге, тут немає мішка потрібно просто витрусити
                  контейнер на 2,5 літра, сполоснути, і все чисто. Він легкий (5
                  кг), компактний, тому не займає купу місця і його зручно
                  тягати по квартирі. Плюс телескопічна труба, насадки,
                  автоматичне змотування шнура – все, що треба для комфортного
                  прибирання. Ну і важливий момент – тут НЕРА-фільтр, він
                  затримує пил, щоб він не повертався у повітря.
                </PrimaryText>

                <PrimaryText>
                  Такий пилосос коштує 4282 грн, а вам доступний з урахуванням
                  ваших бонусів всього за 2799 грн, тобто ваша економія складає
                  1483 грн. Додатково до цього пилососа я можу додати Швабра
                  лентяйка - Cleaner 360 у подарунок. Що скажете, оформимо?
                </PrimaryText>
              </div>

              <div id="objections-34">
                <OrangeBackgroundPrimaryTitle>
                  Відправте на Вайбер фото товару
                </OrangeBackgroundPrimaryTitle>
                <PrimaryText>
                  <GreenTextColor>(Ім'я клієнта)</GreenTextColor>, нажаль немає
                  технічної можливості відправити фото товару на Вайбер, так як
                  немає інтеграції з жодним месенджером. Це пов'язано з тим що в
                  месенджерах трапляються шахрайські випадки та злами аккаунтів.
                  Наша компанія цінує безпеку кожного клієнта і максимально
                  безпечно зберігає дані наших клієнтів, щоб не було ніякого
                  витоку даних та ніхто не міг скористатися Вашими даними,
                  особливо під час війни.
                </PrimaryText>

                <PrimaryText>
                  Але ми не беремо передплати, тобто ви можете повністю оглянути
                  товар на відділені Нової почти, перевірити як він працює і
                  тільки після Ви його оплачуєте. Наприклад зараз Ви можете
                  скористатися знижкою на...{" "}
                  <RedTextColor>
                    (Розповісти про 1 із товарів, та озвучити ціну зі знижкою та
                    без знижки).
                  </RedTextColor>
                </PrimaryText>
              </div>
            </section>
          </div>

          <PrimaryList>
            <PimaryItem>
              <ScriptNavLink href="#greeting">
                Привітання, Виявлення потреб, Презентація.
              </ScriptNavLink>
            </PimaryItem>

            <SecondaryList>
              <SecondaryItem>
                <ScriptNavLink href="#objections-32">
                  Не планував / Не потрібно / Не цікавить
                </ScriptNavLink>
              </SecondaryItem>
            </SecondaryList>

            <PimaryItem>
              <ScriptNavLink href="#additional-offers">
                Пропозиція додаткового товару.
              </ScriptNavLink>
            </PimaryItem>

            <PimaryItem>
              <ScriptNavLink href="#objections-handling">
                Відпрацювання заперечень до додаткових товарів.
              </ScriptNavLink>
            </PimaryItem>

            <SecondaryList>
              <SecondaryItem>
                <ScriptNavLink href="#objections-1">
                  А скільки у мене бонусів? Скільки товарів я можу купити?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-2">
                  Мені не потрібно кілька товарів
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-3">Дорого</ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-4">Якість</ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-5">
                  Подумаю/Пораджусь
                </ScriptNavLink>
              </SecondaryItem>
            </SecondaryList>

            <PimaryItem>
              <ScriptNavLink href="#completion-agreement">
                Завершення угоди
              </ScriptNavLink>
            </PimaryItem>

            <PimaryItem>
              <ScriptNavLink href="#main-objections-handling">
                Відпрацюваня заперечень
              </ScriptNavLink>
            </PimaryItem>

            <SecondaryList>
              <SecondaryItem>
                <ScriptNavLink href="#objections-33">
                  Переведіть на ЗСУ
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-34">
                  Відправте на Вайбер фото товару
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-6">
                  Як можна використати бонуси?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-7">
                  Звідки я маю ці бонуси?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-8">
                  З якої ви компанії?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-9">
                  Доставка платна?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-10">
                  Звідки у вас мій номер?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-11">
                  Товар якісний?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-12">
                  Чому така знижка?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-13">
                  У вас є гарантія?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-14">
                  Якщо товар зламається?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-15">
                  Звідки буде доставка?
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-16">
                  Я нічого не купував у магазинах електроніки.
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-17">
                  Я не маю часу вас слухати. Мені незручно розмовляти!
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-18">
                  Давайте обговоримо пізніше/Мені ніколи:
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-20">
                  Не хочу використовувати бонуси, можете їх анулювати / Я хочу
                  певний товар, а якщо немає його, то нічого не треба
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-21">
                  Я хочу певний товар, якщо його немає – нічого не потрібне.
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-22">
                  Не знаю, що мені робити з бонусами.
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-23">
                  Дорого/Знайшли в іншому магазині дешевше.
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-24">
                  Я не можу придбати товар, не бачачи його.
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-25">
                  Скиньте посилання на сайт, де я можу подивитися все і вибрати.
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-26">
                  Немає грошей
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-27">Я подумаю</ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-28">
                  Нині свята, карантин, складності та криза. Не до вас
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-29">
                  У вас мають бути мої дані, якщо у вас є мій номер телефону
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-30">
                  Я хочу порадитися з чоловіком/дружиною
                </ScriptNavLink>
              </SecondaryItem>

              <SecondaryItem>
                <ScriptNavLink href="#objections-31">
                  Подивлюся ціну в інтернеті
                </ScriptNavLink>
              </SecondaryItem>
            </SecondaryList>
          </PrimaryList>
        </div>
      </Main>
      <ScrollToTopButton />
      <Outlet />
    </div>
  );
};
