import PropTypes from "prop-types";
import { ButtonGroup, ConfirmBtn, CancelBtn } from "./ConfirmDeleteModal.styled";
import { Modal } from "../Modal/Modal";
import confirmDelete from "../../images/confirm-delete.png"; 

export const ConfirmDeleteModal = ({ closeModal, onConfirm }) => {
  return (
    <Modal closeModal={closeModal}>
      <h2>Ви впевнені, що хочете видалити цей елемент?</h2>
      <img src={confirmDelete} alt="Ви впевнені, що хочете видалити цей елемент?" style={{width: '350px'}}/>
      <ButtonGroup>
        <ConfirmBtn onClick={() => { onConfirm(); closeModal(); }}>Видалити</ConfirmBtn>
        <CancelBtn onClick={closeModal}>Скасувати</CancelBtn>
      </ButtonGroup>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};