import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Input,
  Option,
  FilterSelect,
  FilterOption,
  TrashIconButton,
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
  OrderSelect,
} from "./PrimaryBalanceList";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { fetchUsers } from "../../../api/users";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import {
  addNewAmountForAdittionalBonus,
  fetchBalanceAll,
  updateBalance,
} from "../../../api/balance";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { IncomeIcon } from "../../GlobalComponentsAndStyles/IncomeIcon";
import { OutcomeIcon } from "../../GlobalComponentsAndStyles/OutcomeIcon";
import { formatDateToInput } from "../../../helpers/dateAndTime/formatDate";
import Select from "react-select";
import { ConfirmDeleteModal } from "../../ConfirmDeleteModal/ConfirmDeleteModal";

export const PrimaryBalanceList = () => {
  const [addingNewRoute, setAddingNewRoute] = useState(false);
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    amount: "",
    user_id: "",
    comment: "",
    salary_day: "",
    payment_option: "",
    full_name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedPaymentOperation, setSelectedPaymentOperation] = useState("");
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const handleCancelEdit = () => {
    resetValue();
    setAddingNewRoute(false);
    setIsUpdate(false);
  };

  const handleEditRoute = async (item, isDelete) => {
    const updatedFormData = {
      ...item,
      status_message_id: isDelete ? 4 : item.status_message_id,
    };

    if (isDelete) {
      await setIsModalOpen(updatedFormData);
      return;
    }

    setFormData(updatedFormData);
    setIsUpdate(true);
    setAddingNewRoute(true);
  };

  const { data, refetch } = useQuery(
    [
      "balance",
      {
        currentPage,
        pageLimit,
        selectedEmployee,
        selectedPaymentOperation,
      },
    ],
    () =>
      fetchBalanceAll({
        limit: pageLimit,
        page: currentPage,
        employee: selectedEmployee,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
        paymentOption: selectedPaymentOperation,
      })
  );

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({
      sortBy: "full_name",
      order: "ASC",
      jobTitle: "eab7c78f-1559-4bed-8a2a-67d97240f6c6",
      isBlocked: "false",
    })
  );

  const resetValue = () => {
    setFormData({
      id: "",
      amount: "",
      user_id: "",
      comment: "",
      salary_day: "",
      payment_option: "",
    });
  };

  const handleDelete = async (data) => {
    const response = await updateBalance({ ...data, status_message_id: 4 });

    if (response?.status === 200) {
      toast.success("Суму видалено");
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  const handleUpdate = async () => {
    const getUpdatedFormData = {
      ...formData,
      user_id: formData.user_id?.value || "",
    };
    const response = await updateBalance(getUpdatedFormData);

    if (response?.status === 200) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  const handleSave = async () => {
    const getUpdatedFormData = {
      ...formData,
      user_id: formData.user_id?.value || "",
    };
    const response = await addNewAmountForAdittionalBonus(getUpdatedFormData);

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const getIcon = (value) => {
    if (value === "income") {
      return <IncomeIcon />;
    } else {
      return <OutcomeIcon />;
    }
  };

  return (
    <Main>
      <div
        style={{
          margin: "20px",
          display: "flex",
          alignItems: "center",
          heigh: "25px",
          flexWrap: "wrap",
        }}
      >
        <div>{`${formattedStartDate} - ${formattedEndDate}`}</div>

        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "200px", heigh: "15px", marginLeft: "10px" }}
        >
          Оберіть період
        </Button>

        <label style={{ marginLeft: "10px" }}>Оберіть оператора: </label>
        <FilterSelect
          value={selectedEmployee}
          onChange={(e) => {
            setSelectedEmployee(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Виберіть оператора</Option>
          {usersData?.usersData?.map((user) => (
            <FilterOption key={user?.id} value={user?.id}>
              {user?.full_name}
            </FilterOption>
          ))}
        </FilterSelect>

        <label style={{ marginLeft: "10px" }}>Оберіть операцію: </label>
        <FilterSelect
          value={selectedPaymentOperation}
          onChange={(e) => {
            setSelectedPaymentOperation(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Виберіть операцію</Option>

          <FilterOption key={1} value={"income"}>
            Вхідна
          </FilterOption>
          <FilterOption key={2} value={"outcome"}>
            Вихідна
          </FilterOption>
        </FilterSelect>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>
      {isModalOpen !== false && (
        <ConfirmDeleteModal
          closeModal={() => setIsModalOpen(false)}
          onConfirm={() => handleDelete(isModalOpen)}
        />
      )}
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh style={{ width: "80px" }}>Дата нарахуваня</RouteTh>
            <RouteTh>ПІБ оператора</RouteTh>
            <RouteTh style={{ width: "80px" }}>Сума</RouteTh>
            <RouteTh style={{ width: "582px" }}>Коментар</RouteTh>
            <RouteTh style={{ width: "80px" }}>Дата виплати</RouteTh>
            <RouteTh style={{ width: "80px" }}>Операція</RouteTh>
            <RouteTh>Статус повідмоленя</RouteTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          <Tr>
            {addingNewRoute && !isUpdated ? (
              <>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd>
                  <Select
                    value={{
                      label:
                        formData.user_id.label ||
                        formData.full_name ||
                        "Виберіть оператора",
                      value: formData.user_id.value || formData.user_id || "0",
                    }}
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        user_id: selectedOption,
                      }))
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: "3",
                        width: "100%",
                      }),
                    }}
                    options={[
                      { label: "Виберіть оператора", value: "0" },
                      ...(usersData?.usersData || []).map((user) => ({
                        label: user.full_name,
                        value: user.id,
                      })),
                    ]}
                    placeholder="Виберіть оператора"
                  />
                </RouteTd>
                <RouteTd style={{ width: "102px" }}>
                  <Input
                    type="number"
                    value={formData.amount}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        amount: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd style={{ width: "604px" }}>
                  <Input
                    type="text"
                    value={formData.comment}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        comment: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd style={{ width: "102px" }}>
                  <Input
                    type="date"
                    value={formatDateToInput(formData.salary_day)}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        salary_day: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  />
                </RouteTd>

                <RouteTd style={{ width: "102px" }}>
                  <OrderSelect
                    value={formData?.payment_option}
                    onChange={(e) => {
                      const updatedFormData = {
                        ...formData,
                        payment_option: e.target.value,
                      };
                      return setFormData(updatedFormData);
                    }}
                  >
                    <Option value="">Виберіть операцію</Option>
                    <Option key={1} value={"income"}>
                      Вхідна
                    </Option>
                    <Option key={2} value={"outcome"}>
                      Вихідна
                    </Option>
                  </OrderSelect>
                </RouteTd>

                <RouteTd>{formData.message_status_title}</RouteTd>

                <Td>
                  <IconLinkContainer>
                    <IconButton onClick={handleSave}>
                      <FaSave size="20px" />
                    </IconButton>
                    <TrashIconButton onClick={() => handleCancelEdit()}>
                      <MdCancel size="25px" />
                    </TrashIconButton>
                  </IconLinkContainer>
                </Td>
              </>
            ) : (
              <>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd style={{ width: "604px" }}></RouteTd>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd></RouteTd>
                <Td>
                  <IconButton
                    onClick={() => setAddingNewRoute(true)}
                    style={{ width: "75%" }}
                  >
                    <MdOutlineAddCircle size="25px" />
                  </IconButton>
                </Td>
              </>
            )}
          </Tr>
          {data?.data?.length > 0 && (
            <React.Fragment>
              {data?.data?.map((item) => (
                <Tr key={item?.id}>
                  {addingNewRoute && isUpdated && formData?.id === item?.id ? (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.createdAt)}
                      </RouteTd>
                      <RouteTd>
                        <Select
                          value={{
                            label:
                              formData.user_id.label ||
                              formData.full_name ||
                              "Виберіть оператора",
                            value:
                              formData.user_id.value || formData.user_id || "0",
                          }}
                          onChange={(selectedOption) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              user_id: selectedOption,
                            }))
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "100%",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: "3",
                              width: "100%",
                            }),
                          }}
                          options={[
                            { label: "Виберіть оператора", value: "0" },
                            ...(usersData?.usersData || []).map((user) => ({
                              label: user.full_name,
                              value: user.id,
                            })),
                          ]}
                          placeholder="Виберіть оператора"
                        />
                      </RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        <Input
                          type="number"
                          value={formData.amount}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              amount: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "604px" }}>
                        <Input
                          type="text"
                          value={formData.comment}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              comment: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "102px" }}>
                        <Input
                          type="date"
                          value={formatDateToInput(formData.salary_day)}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              salary_day: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>

                      <RouteTd style={{ width: "102px" }}>
                        <OrderSelect
                          value={formData?.payment_option}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              payment_option: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        >
                          <Option value="">Виберіть операцію</Option>
                          <Option key={1} value={"income"}>
                            Вхідна
                          </Option>
                          <Option key={2} value={"outcome"}>
                            Вихідна
                          </Option>
                        </OrderSelect>
                      </RouteTd>

                      <RouteTd>{formData.message_status_title}</RouteTd>

                      <Td>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="20px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="25px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.createdAt)}
                      </RouteTd>
                      <RouteTd>{item.full_name}</RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        {item?.amount} грн
                      </RouteTd>

                      <RouteTd style={{ width: "604px" }}>
                        {item?.comment}
                      </RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.salary_day)}
                      </RouteTd>
                      <RouteTd style={{ width: "102px" }}>
                        {getIcon(item.payment_option)}
                      </RouteTd>
                      <RouteTd>{item?.message_status_title}</RouteTd>

                      <Td>
                        {formData?.id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton
                              onClick={() => handleEditRoute(item, false)}
                            >
                              <FaEdit size="20px" />
                            </IconButton>
                            <TrashIconButton
                              onClick={() => handleEditRoute(item, true)}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
