import styled from "@emotion/styled";
import { Button } from "../GlobalComponentsAndStyles/GlobalStyles.styled";

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

export const ConfirmBtn = styled(Button)`
  width: 50%;
  background-color: #27ae60;

  &:hover,
  &:focus,
  &.active {
    background-color: green;
    color: #ffffff;
  }
`;

export const CancelBtn = styled(ConfirmBtn)`
  width: 50%;
  background-color: #e74c3c;

  &:hover,
  &:focus,
  &.active {
    background-color: #c0392b;
    color: #ffffff;
  }
`;
