import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Text,
  Option,
  TrashIconButton,
  FilterSelect,
  FilterOption,
  Button,
  GlobalLink,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
} from "./OrderListPushToPickup.styled";
import { FaTrash, FaEdit } from "react-icons/fa";
import { MdOutlineAddCircle } from "react-icons/md";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Pagination } from "../../Pagination/Pagination";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { formattedDateToDayMonthYearWithTime } from "../../../helpers/dateAndTime/formattedDateToDayMonthYearWithTime";
import {
  deleteOrderPushToPickup,
  fetchOrderMePushToPickup,
} from "../../../api/orderPushToPickup";
import { fetchTakeOrderStatuses } from "../../../api/takeOrderStatuses";

import {
  UserFaMinusCircle,
  UserFaTimesCircle,
} from "../../Users/UsersList/UserList.styled";
import { fetchReturnOrderStatuses } from "../../../api/returnOrderStatuses";
import { ConfirmDeleteModal } from "../../ConfirmDeleteModal/ConfirmDeleteModal";

export const OrderListPushToPickup = () => {
  const sortBy = "createdAt";
  const order = "DESC";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTakeOrderStatus, setSelectedTakeOrderStatus] = useState("");
  const [selectedReturnOrderStatus, setSelectedReturnOrderStatus] =
    useState("");
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 3, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data: orders, refetch } = useQuery(
    [
      "ordersPushToPickupMe",
      {
        currentPage,
        pageLimit,
        sortBy,
        order,
        selectedTakeOrderStatus,
        selectedReturnOrderStatus,
      },
    ],
    () =>
      fetchOrderMePushToPickup({
        limit: pageLimit,
        page: currentPage,
        sortBy,
        order,
        pickupDateStatus: selectedTakeOrderStatus,
        nonPurchaseReasonStatus: selectedReturnOrderStatus,
        startDate: formattedStartDateRequest,
        endDate: formattedEndDateRequest,
      })
  );

  const { data: takeOrderStatusData } = useQuery(
    "takeOrderStatus",
    fetchTakeOrderStatuses
  );

  const { data: returnOrderStatusData } = useQuery(
    "returnOrderStatusData",
    fetchReturnOrderStatuses
  );

  const handleDelete = async (id) => {
    const response = await deleteOrderPushToPickup(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      refetch();
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (orders) {
      setTotalPages(orders?.totalPages);
    }
  }, [orders, pageLimit]);

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    setIsModalDateOpen(false);
  };

  const getIcon = (value) => {
    if (value === "true") {
      return <UserFaMinusCircle fill="green" />;
    } else {
      return <UserFaTimesCircle fill="red" />;
    }
  };

  return (
    <Main>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}
      {isModalOpen !== false && (
        <ConfirmDeleteModal
          closeModal={() => setIsModalOpen(false)}
          onConfirm={() => handleDelete(isModalOpen)}
        />
      )}
      <div style={{ margin: "20px", display: "flex", alignItems: "center" }}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "150px" }}
        >
          Оберіть період
        </Button>
        <label style={{ marginLeft: "10px" }}>Оберіть коли забере: </label>
        <FilterSelect
          value={selectedTakeOrderStatus}
          onChange={(e) => {
            setSelectedTakeOrderStatus(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Виберіть коли забере</Option>
          {takeOrderStatusData?.map((status) => (
            <FilterOption key={status?.id} value={status?.id}>
              {status?.title}
            </FilterOption>
          ))}
        </FilterSelect>

        <label style={{ marginLeft: "10px" }}>
          Оберіть причину не викупу:{" "}
        </label>
        <FilterSelect
          value={selectedReturnOrderStatus}
          onChange={(e) => {
            setSelectedReturnOrderStatus(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Оберіть причину не викупу</Option>
          {returnOrderStatusData?.map((status) => (
            <FilterOption key={status?.id} value={status?.id}>
              {status?.title}
            </FilterOption>
          ))}
        </FilterSelect>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>
      <Table>
        <Thead>
          <HeadTr>
            <Th>Панeль управліня</Th>
            <RouteTh style={{ width: "80px" }}>Дата</RouteTh>
            <RouteTh style={{ width: "80px" }}>Номер замовленя</RouteTh>
            <RouteTh>Надходив дзвінок від компанії? </RouteTh>
            <RouteTh style={{ width: "90px" }}>
              Чи надходили смс із ТТН?
            </RouteTh>
            <RouteTh style={{ width: "90px" }}>Коли забере</RouteTh>
            <RouteTh style={{ width: "250px" }}>Причина не викупу</RouteTh>
            <RouteTh>Коментар</RouteTh>
          </HeadTr>
        </Thead>
        <tbody>
          <Tr>
            <>
              <Td>
                <IconButton
                  to={`/order/list-push-to-pickup/create/`}
                  style={{ width: "75%" }}
                >
                  <MdOutlineAddCircle size="25px" />
                </IconButton>
              </Td>
              <RouteTd style={{ width: "102px" }}></RouteTd>
              <RouteTd style={{ width: "102px" }}></RouteTd>
              <RouteTd></RouteTd>
              <RouteTd style={{ width: "112px" }}></RouteTd>
              <RouteTd style={{ width: "112px" }}></RouteTd>
              <RouteTd style={{ width: "270px" }}></RouteTd>
              <RouteTd></RouteTd>
            </>
          </Tr>
          {orders?.data?.length > 0 && (
            <React.Fragment>
              {orders?.data?.map((item) => (
                <Tr key={item?.id}>
                  <>
                    <Td>
                      <IconLinkContainer>
                        <IconButton
                          to={`/order/list-push-to-pickup/change/${item.order_number}`}
                        >
                          <FaEdit size="20px" />
                        </IconButton>
                        <TrashIconButton
                          onClick={() => setIsModalOpen(item.id)}
                        >
                          <FaTrash size="20px" />
                        </TrashIconButton>
                      </IconLinkContainer>
                    </Td>
                    <RouteTd style={{ width: "102px" }}>
                      {formattedDateToDayMonthYearWithTime(item.createdAt)}
                    </RouteTd>
                    <RouteTd style={{ width: "102px" }}>
                      {item.order_status_id ===
                      takeOrderStatusData?.find(
                        (status) => status?.title === "Передзвон"
                      )?.id ? (
                        <GlobalLink
                          href={`https://crm.7leads.xyz/order/view?id=${item.order_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item?.order_number}
                        </GlobalLink>
                      ) : (
                        item?.order_id
                      )}
                    </RouteTd>
                    <RouteTd>
                      {getIcon(item?.incoming_call_from_company)}
                    </RouteTd>
                    <RouteTd style={{ width: "112px" }}>
                      {getIcon(item?.sms_notification_sent)}
                    </RouteTd>
                    <RouteTd style={{ width: "112px" }}>
                      <Text>{item?.take_order_statuses_title}</Text>
                    </RouteTd>
                    <RouteTd style={{ width: "270px" }}>
                      <Text>{item?.return_order_statuses_title}</Text>
                    </RouteTd>

                    <RouteTd>{item?.comment}</RouteTd>
                  </>
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
