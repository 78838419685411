import React, { useState } from "react";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { RouteTh, RouteTd } from "./SummaryBalance.styled";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { fetchBalanceOverallSummary, fetchBalanceOverallSummaryByEmployee } from "../../../api/balance";
import { Main } from "../../GlobalComponentsAndStyles/Main";

export const SummaryBalance = () => {
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [sortBy, setSortBy] = useState("total_sum");
  const [order, setOrder] = useState("DESC");
  const [period, setPeriod] = useState([
    {
      startDate: new Date(currentYear, currentMonth - 2, currentDate.getDate()),
      endDate: new Date(currentYear, currentMonth - 1, currentDate.getDate()),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data: overrallSummaryData, refetch } = useQuery(["balance-overrall-summary", { sortBy, order }], () =>
    fetchBalanceOverallSummary({
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    })
  );

  const { data: overrallSummaryByEmployeeData, refetch: refetchOverrallSummaryByEmployeeData } = useQuery(["balance-overrall-summary-by-emploee", { sortBy, order }], () =>
    fetchBalanceOverallSummaryByEmployee({
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
      sortBy,
      order
    })
  );

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    refetchOverrallSummaryByEmployeeData()
    setIsModalDateOpen(false);
  };

  const handleSortChange = (field) => {
    setSortBy(field);
    setOrder(order === "ASC" ? "DESC" : "ASC");
    refetch();
    refetchOverrallSummaryByEmployeeData()
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <Main>
      <div
        style={{
          margin: "20px",
          display: "flex",
          alignItems: "center",
          heigh: "25px",
          flexWrap: "wrap",
        }}
      >
        <div>{`${formattedStartDate} - ${formattedEndDate}`}</div>

        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "200px", heigh: "15px", marginLeft: "10px" }}
        >
          Оберіть період
        </Button>
      </div>

      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table style={{ marginBottom: "20px" }}>
        <Thead>
          <HeadTr>
            <RouteTh>За погодину оплату</RouteTh>
            <RouteTh>Бонус за замовленя</RouteTh>
            <RouteTh>Приведи друга</RouteTh>
            <RouteTh>Додатковий бонус</RouteTh>
            <RouteTh>Всього</RouteTh>
          </HeadTr>
        </Thead>
        <tbody>
          {overrallSummaryData?.length > 0 && (
            <React.Fragment>
              <Tr>
                <>
                  <RouteTd>
                    {overrallSummaryData[0]?.overall_schedule_sum} грн
                  </RouteTd>

                  <RouteTd>{overrallSummaryData[0]?.overall_order_sum} грн</RouteTd>
                  <RouteTd>{overrallSummaryData[0]?.referral_program_sum} грн</RouteTd>
                  <RouteTd>{overrallSummaryData[0]?.additional_bonus_sum} грн</RouteTd>
                  <RouteTd>{overrallSummaryData[0]?.overall_total_sum} грн</RouteTd>
                </>
              </Tr>
            </React.Fragment>
          )}
        </tbody>
      </Table>

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh onClick={() => handleSortChange("full_name")}>
              ПІБ {renderSortIcon("full_name")}
            </RouteTh>
            <RouteTh>Номер карти</RouteTh>
            <RouteTh onClick={() => handleSortChange("schedule_sum")}>
              За погодину оплату {renderSortIcon("schedule_sum")}
            </RouteTh>
            <RouteTh onClick={() => handleSortChange("order_sum")}>
              Бонус за замовленя {renderSortIcon("order_sum")}
            </RouteTh>
            <RouteTh onClick={() => handleSortChange("referral_program_sum")}>
            Приведи друга {renderSortIcon("referral_program_sum")}
            </RouteTh>
            <RouteTh onClick={() => handleSortChange("additional_bonus_sum")}>
            Додатковий бонус {renderSortIcon("additional_bonus_sum")}
            </RouteTh>
            <RouteTh onClick={() => handleSortChange("total_sum")}>
              Всього {renderSortIcon("total_sum")}
            </RouteTh>
          </HeadTr>
        </Thead>
        <tbody>
          {overrallSummaryByEmployeeData?.length > 0 && (
            <React.Fragment>
              {overrallSummaryByEmployeeData?.map((item) => (
                <Tr key={item?.full_name}>
                  <>
                    <RouteTd>{item?.full_name}</RouteTd>
                    <RouteTd>{item?.card}</RouteTd>

                    <RouteTd>{item?.schedule_sum} грн</RouteTd>
                    <RouteTd>{item?.order_sum} грн</RouteTd>
                    <RouteTd>{item?.referral_program_sum} грн</RouteTd>
                    <RouteTd>{item?.additional_bonus_sum} грн</RouteTd>
                    <RouteTd>{item?.total_sum} грн</RouteTd>
                  </>
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
    </Main>
  );
};
