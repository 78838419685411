import styled from "@emotion/styled";

export const NotFoundContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

export const NotFoundImage = styled.img`
width: 500px;
`

export const NotFoundText = styled.p`
width: 500px;
text-align: center;
`